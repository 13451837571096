import React, { useCallback } from 'react';

import Row from '#materials/Row';
import Box from '#materials/Box';

import { Address, Customer } from '#mrktbox/types';
import { useCustomers } from '#mrktbox/hooks';

import ButtonLink from '#materials/ButtonLink';
import OrderAddress  from '#components/addresses/OrderAddress';

interface AddressDetailsProps {
  addresses : Address[];
  customer : Customer;
}

function AddressDetails({
  addresses : init,
  customer,
} : AddressDetailsProps) {
  const { removeAddressFromCustomer } = useCustomers();

  const handleRemoveAddress = useCallback(async (address: Address) => {
    await removeAddressFromCustomer(customer, address);
  }, [removeAddressFromCustomer, customer]);

  return (init &&
      init.map((address) => {
      return (
        <Box>
          <Row
            key={address.id}
            content={
              <OrderAddress address={address} isCard={true}>
                <p>
                  <ButtonLink onClick={() => handleRemoveAddress(address)}>
                    remove
                  </ButtonLink>
                </p>
              </OrderAddress>
            }
          />
        </Box>
      )
    })
  );
}

export default AddressDetails;
