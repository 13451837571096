import { useCallback, useEffect, useState } from 'react';

import { Search, SearchIdentifier, SearchIndex } from '#mrktbox/clerk/types';

import useUsers from '#mrktbox/clerk/hooks/useUsers';
import useSearchAPI from '#mrktbox/clerk/hooks/api/useSearchAPI';

import { buildSearch, querySearch } from '#mrktbox/clerk/utils/search';

export interface SearchResults<RT> {
  results : {
    [id : SearchIdentifier] : {
      record : RT;
      score : number;
    };
  };
  sorted : RT[];
};

interface useSearchProps<RT> {
  url : string;
  records : { [id : SearchIdentifier] : RT | null } | null;
  requirePermissions? : string[];
};

function useSearch<RT>({
  url,
  records,
  requirePermissions,
} : useSearchProps<RT>) {
  const { checkAuth } = useUsers();
  const {
    retrieveIndex,
    regenerateIndex,
  } = useSearchAPI();

  const [index, setIndex] = useState<SearchIndex | null>(null);
  const [search, setSearch] = useState<Search | null>(null);

  const pullIndex = useCallback(async () => {
    const auth = !requirePermissions || checkAuth({
      permissions : requirePermissions,
    });
    if (!auth) return null;

    const index = await retrieveIndex(url);
    if (!!index) setIndex(index);
    return index;
  }, [url, requirePermissions, retrieveIndex, checkAuth]);

  const pingIndex = useCallback(async () => {
    const success = await regenerateIndex(url);
    if (!success) return false;

    return !!await pullIndex();
  }, [url, pullIndex, regenerateIndex]);

  const makeQuery = useCallback((query : string) => {
    if (!records || !search || !query) return null;

    const ids = querySearch(search, query);
    if (!ids.length) return null;

    const searchResults = { results : {}, sorted : [] } as SearchResults<RT>;
    let i = ids.length;
    ids.forEach(id => {
      const record = records[id];
      if (record) {
        searchResults.results[id] = {
          record : record,
          score : i--, // TODO: use actual score
          // for now, just use the inverse order of returned ids
        };
        searchResults.sorted.push(record);
      }
    });
    return searchResults;
  }, [records, search]);

  useEffect(() => {
    pullIndex();
  }, [pullIndex]);

  useEffect(() => {
    if (!index) return;
    setSearch(buildSearch(index));
  }, [index]);

  return {
    search : makeQuery,
    regenerate : pingIndex,
  };
}

export default useSearch;
