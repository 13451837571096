import React, { createContext, useCallback } from 'react';

import {
  ServiceChannel,
  Product,
  Tag,
  isTag,
} from '#mrktbox/clerk/types';

import useData, {
  actionTypes,
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import useTagsAPI from '#mrktbox/clerk/hooks/api/useTagsAPI';

export interface TagIndex extends DataIndex<Tag> {}

const MAX_AGE = 1000 * 60 * 60;

const TagContext = createContext({
  tags: null as DataIndex<Tag> | null,
  loaded: false,
  load: () => {},
  cacheTags: <T extends Tag>(
    tag: DataIndex<T> | T | null
  ) => null as DataIndex<T> | null,
  refreshTags: async () => null as TagIndex | null,
  refreshTag: async (id : number) => null as Tag | null,
  createTag: async (tag : Tag) => null as Tag | null,
  retrieveTags: async () => null as TagIndex | null,
  retrieveTag: async (id : number) => null as Tag | null,
  updateTag: async (tag : Tag) => null as Tag | null,
  deleteTag: async (tag : Tag) => null as boolean | null,
  addProductToTag: async (
    tag : Tag,
    product : Product,
  ) => null as boolean | null,
  removeProductFromTag: async (
    tag : Tag,
    product : Product,
  ) => null as boolean | null,
  addServiceChannelToTag: async (
    tag : Tag,
    serviceChannel : ServiceChannel,
  ) => null as boolean | null,
  removeServiceChannelFromTag: async (
    tag : Tag,
    serviceChannel : ServiceChannel,
  ) => null as boolean | null,
});

interface TagProviderProps {
  children : React.ReactNode;
}

export function TagProvider({ children } : TagProviderProps) {
  const {
    createTag,
    retrieveTags,
    retrieveTag,
    updateTag,
    deleteTag,
    addProductToTag,
    removeProductFromTag,
    addServiceChannelToTag,
    removeServiceChannelFromTag,
  } = useTagsAPI();

  const {
    data : tags,
    dispatch : dispatchTags,
    lastUpdated,
  } = useData<Tag>({ storageKey : 'tags' });

  const cacheTags = useCallback(
    <T extends Tag>(tags : DataIndex<T> | T | null) => {
      if (!tags) return null;
      const index = isTag(tags)
        ? (tags.id ? { [tags.id] : tags } : {})
        : tags;

      dispatchTags({
        type : actionTypes.add,
        data : index,
      });
      return index;
    },
    [dispatchTags],
  );

  const newTag = useChange({
    dispatch : dispatchTags,
    change : createTag,
  });
  const refreshTags = useRefreshIndex({
    dispatch : dispatchTags,
    retrieve : retrieveTags,
  });
  const refreshTag = useRefresh({
    dispatch : dispatchTags,
    retrieve : retrieveTag,
  });
  const getTags = useRetrieveIndex({
    data : tags,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshTags,
  });
  const getTag = useRetrieve({
    data : tags,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshTag,
  });
  const amendTag = useChange({
    dispatch : dispatchTags,
    change : updateTag,
  });
  const removeTag = useDelete({
    dispatch : dispatchTags,
    delete : deleteTag,
  });

  const addProduct = useRelate({
    dispatch : dispatchTags,
    relate : addProductToTag,
    callback : refreshTags,
  });
  const removeProduct = useRelate({
    dispatch : dispatchTags,
    relate : removeProductFromTag,
    callback : refreshTags,
  });
  const addServiceChannel = useRelate({
    dispatch : dispatchTags,
    relate : addServiceChannelToTag,
    callback : refreshTags,
  });
  const removeServiceChannel = useRelate({
    dispatch : dispatchTags,
    relate : removeServiceChannelFromTag,
    callback : refreshTags,
  });

  const { loaded, load } = useLoad({
    data : tags,
    loader : refreshTags,
  });

  const context = {
    tags,
    loaded,
    load,
    cacheTags,
    refreshTags,
    refreshTag,
    createTag : newTag,
    retrieveTags : getTags,
    retrieveTag : getTag,
    updateTag : amendTag,
    deleteTag : removeTag,
    addProductToTag : addProduct,
    removeProductFromTag : removeProduct,
    addServiceChannelToTag : addServiceChannel,
    removeServiceChannelFromTag : removeServiceChannel,
  };

  return (
    <TagContext.Provider value={context}>
      { children }
    </TagContext.Provider>
  );
}

export default TagContext;
