import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Product, LineItem } from '#mrktbox/types';
import { useOptions, useSubscriptions } from '#mrktbox';

import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import { Theme } from '#types';

import Body from '#materials/Body';
import ProductHeader from '#components/products/ProductHeader';
import ProductFooter from '#components/products/ProductFooter';
import ProductTags from '#components/products/ProductTags';
import ProductAccordion from '#components/products/ProductAccordion';
import ProductItemImage from '#components/products/ProductItemImage';
import ProductOptions from '#components/products/ProductOptions';

interface Style {
  theme? : Theme;
}

const ProductView = styled.div`
  label: ProductView;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
`;

const ProductContent = styled.div<Style>`
  label: ProductContent;
  flex: 1 1 auto;
  background-color: lightblue;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.bgColours.primary};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProductDetails = styled.div<Style>`
  padding: 0 ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 ${(props) => props.theme.item.mobile.padding};
  }
`;

const ProductDesc = styled(Body)<Style>`
  margin: 1rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`;

interface ProductItemProps {
  product : Product;
  quantity : number;
  lineItem? : LineItem | null;
  setQuantity : (quantity: number) => void;
}

function ProductItem({
  product,
  quantity,
  lineItem,
  setQuantity,
} : ProductItemProps){
  const productContentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  const { isProductCustomisable } = useOptions();
  const { findLineItemSubscription } = useSubscriptions();

  const { allProducts : products, getProductTags } = useCatalogue();
  const {
    time,
    currentOrder,
    evaluateReccurence,
    canSubscribe,
  } = useRequests();

  const getCurrentSelection = useCallback(() => (
    (lineItem && currentOrder && products)
      ? Object.values(currentOrder.selections).filter(
        s => s.lineItemId === lineItem.id,
      ).reduce((acc, s) => {
        const product = products[s.productId];
        if (!product) return acc;

        if (!acc[s.assemblyId]) acc[s.assemblyId] = [];
        for (let q = 0; q < s.quantity; q++) {
          acc[s.assemblyId].push(product);
        }
        return acc;
      }, {} as { [id : number] : Product[] })
      : {}
  ), [lineItem, currentOrder, products]);

  const determinePeriod = useCallback(() => {
    const subcription = (lineItem && currentOrder)
      ? (findLineItemSubscription(lineItem, currentOrder))
      : null;
    if (!subcription) return 0;
    return evaluateReccurence(
      subcription,
      { iteration : currentOrder?.timeSlotIteration }
    );
  }, [lineItem, currentOrder, evaluateReccurence, findLineItemSubscription]);

  const [tags, setTags] = useState(getProductTags(product));
  const [lineItemId, setLineItemId] = useState(lineItem?.id ?? null);
  const [selections, setSelections] = useState(getCurrentSelection());
  const [period, setPeriod] = useState(determinePeriod());
  const [compact, setCompact] = useState(false);
  const [optionsTop, setOptionsTop] = useState(0);

  useEffect(() => {
    if (lineItem?.id !== lineItemId) {
      setLineItemId(lineItem?.id ?? null);
      setSelections(getCurrentSelection())
      setPeriod(determinePeriod());
    }
  }, [lineItem, lineItemId, getCurrentSelection, determinePeriod]);

  useEffect(() => {
    setTags(getProductTags(product));
  }, [product, getProductTags]);

  useEffect(() => {
    const container = productContentRef.current;
    const header = headerRef.current;
    const img = imageRef.current;
    if (!container || !header || !img) return;

    const handleScroll = () => {
      const bottom = (img.offsetTop + img.offsetHeight + 20);
      if (container.scrollTop > bottom) setCompact(true);
      else if (container.scrollTop === 0
        || container.scrollTop < bottom + 16) setCompact(false);
      setOptionsTop(header.offsetHeight - 1);
    }

    productContentRef.current.addEventListener('scroll', handleScroll);
    return () => { container.removeEventListener('scroll', handleScroll); }
  }, [productContentRef, headerRef, imageRef]);

  return (
    <ProductView>
      <ProductContent id="product-content" ref={productContentRef} >
        <ProductItemImage imageRef={imageRef} product={product} />
        <ProductHeader
          product={product}
          selections={selections}
          compact={compact}
          headerRef={headerRef}
        />
        <ProductDetails>
          <ProductTags tags={tags} />
          <ProductDesc as="p">{ product.description }</ProductDesc>
        </ProductDetails>
        <ProductAccordion
          product={product}
          period={period}
          setPeriod={canSubscribe() ? setPeriod : undefined}
        />
        { isProductCustomisable(product, time ?? new Date()) && (
          <ProductOptions
            product={product}
            selections={selections}
            setSelections={setSelections}
            scrollOffset={optionsTop}
            scrollRef={productContentRef}
          />
        ) }
      </ProductContent>
      <ProductFooter
        product={product}
        selections={selections}
        lineItem={lineItem}
        quantity={quantity}
        setQuantity={setQuantity}
        period={period}
      />
    </ProductView>
  );
}

export default ProductItem;
