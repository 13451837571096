import { useCallback, useContext } from 'react';

import {
  Service,
  TimeSlot,
  TimeSlotCache,
  Schedule,
  Window,
  ScheduleSelection,
  isService,
  isSchedule,
} from '#mrktbox/clerk/types';

import ScheduleContext from '#mrktbox/clerk/context/ScheduleContext';

import { listRecords } from '#mrktbox/clerk/utils/data';
import { generateRange } from '#mrktbox/clerk/utils/array';
import {
  timeScales,
  roundDateTime,
  shiftLocalDateTime,
} from '#mrktbox/clerk/utils/date';

function commonDenominator(a : number, b : number) : number {
  return b === 0 ? a : commonDenominator(b, a % b);
}

function findApplicableWindow(
  timeSlot : TimeSlot,
  iteration : number,
  additionalWindows : Window[] = [],
) {
  const windows = Object.values(timeSlot.windows).concat(additionalWindows);
  const applicableWidows = Object.values(windows).filter(
    window => window.fromIteration <= iteration &&
      (window.toIteration === null || window.toIteration >= iteration),
  );
  if (!applicableWidows.length) return undefined;

  const windowByMostRecent = applicableWidows.sort(
    (a, b) => (a.id !== b.id)
      ? (b.id ?? Infinity) - (a.id ?? Infinity)
      : 0,
  );
  return windowByMostRecent[0];
}

function calculateWindowDate(
  timeSlot : TimeSlot,
  window : Window,
  iteration : number,
) {
  if (
    window.fromIteration > iteration ||
    (window.toIteration !== null && window.toIteration < iteration)
  ) throw new Error('Invalid iteration');

  return shiftLocalDateTime(
    window.start,
    timeSlot.scale * timeSlot.period * (iteration - window.fromIteration),
  );
}

function calculateDuration(
  timeSlot : TimeSlot,
  iteration : number,
) {
  const window = findApplicableWindow(timeSlot, iteration);
  return window ? window.duration : timeSlot.duration;
}

function maxDivisions(
  timeSlot : TimeSlot,
  iteration : number,
) {
  if (!timeSlot.division) return 0;
  const duration = calculateDuration(timeSlot, iteration);
  return Math.floor(duration / timeSlot.division) + 1;
}

function useTimeSlots() {
  const {
    schedules,
    timeSlots,
    loaded,
    load,
    createSchedule,
    refreshSchedules,
    refreshSchedule,
    retrieveSchedules,
    retrieveSchedule,
    updateSchedule,
    deleteSchedule,
    addScheduleToService,
    removeScheduleFromService,
    createTimeSlot,
    refreshTimeSlots,
    refreshTimeSlot,
    retrieveTimeSlots,
    retrieveTimeSlot,
    updateTimeSlot,
    deleteTimeSlot,
    addTimeSlotToSchedule,
    removeTimeSlotFromSchedule,
    createWindow,
  } = useContext(ScheduleContext);

  // DEPR: use addServiceToSchedule instead
  const addSchedule = useCallback(
    async (schedule : Service | Schedule, service : Service | Schedule) => {
      if (isSchedule(schedule) && isService(service)) {
        return await addScheduleToService(schedule, service);
      } else if (isSchedule(service) && isService(schedule)) {
        return await addScheduleToService(service, schedule);
      } else {
        throw new Error('Invalid argument combination');
      }
    },
    [addScheduleToService],
  );

  // DEPR: use removeServiceFromSchedule instead
  const removeSchedule = useCallback(
    async (schedule : Service | Schedule, service : Service | Schedule) => {
      if (isSchedule(schedule) && isService(service)) {
        return await removeScheduleFromService(schedule, service);
      } else if (isSchedule(service) && isService(schedule)) {
        return await removeScheduleFromService(service, schedule);
      } else {
        throw new Error('Invalid argument combination');
      }
    },
    [removeScheduleFromService],
  );

  const updateTimeSlotCache = useCallback(
    (timeSlot : TimeSlot, cache : TimeSlotCache) => {
      timeSlot.cache = { ...timeSlot.cache, ...cache }
    },
    [],
  );

  const calculateMinStep = useCallback((
    timeSlot : TimeSlot,
    additionalWindows : Window[] = [],
  ) => {
    if (!additionalWindows.length && timeSlot.cache?.minStep !== undefined) {
      return timeSlot.cache.minStep;
    }

    let minStep : number;
    minStep = commonDenominator(
      timeSlot.scale * timeSlot.period,
      timeSlot.start.getTime(),
    );
    minStep = commonDenominator(minStep, timeSlot.duration)
    if (timeSlot.division) {
      minStep = commonDenominator(minStep, timeSlot.division);
    }
    minStep = Object.values(timeSlot.windows).concat(additionalWindows).reduce(
      (step, window) => commonDenominator(step, window.start.getTime()),
      minStep,
    );
    minStep = Object.values(timeSlot.windows).concat(additionalWindows).reduce(
      (step, window) => commonDenominator(step, window.duration),
      minStep,
    );

    if (!additionalWindows.length && timeSlot.id) {
      updateTimeSlotCache(timeSlot, { minStep });
    }

    return minStep;
  }, [updateTimeSlotCache]);

  const calculateDuration = useCallback((
    timeSlot : TimeSlot,
    iteration : number,
    additionalWindows : Window[] = [],
  ) => {
    // TODO: cache duration
    const applicableWindow = findApplicableWindow(
      timeSlot,
      iteration,
      additionalWindows,
    );
    if (!applicableWindow) return timeSlot.duration;
    else return applicableWindow.duration;
  }, []);

  // DEPR: use calculateTime instead to allow specifying time slot division
  const calculateDate = useCallback((
    timeSlot : TimeSlot,
    iteration : number,
    additionalWindows : Window[] = [],
    useWindows : boolean = true,
  ) => {
    if (!additionalWindows.length && timeSlot.cache?.iterations?.[iteration]) {
      return new Date(timeSlot.cache.iterations[iteration]);
    }

    const applicableWindow = findApplicableWindow(
      timeSlot,
      iteration,
      additionalWindows,
    );
    if (!useWindows || !applicableWindow) {
      const date = shiftLocalDateTime(
        timeSlot.start,
        timeSlot.scale * timeSlot.period * iteration,
      );

      if (!applicableWindow && timeSlot.id) {
        updateTimeSlotCache(
          timeSlot,
          { iterations : {
            ...timeSlot.cache?.iterations,
            [iteration] : date.getTime(),
          } },
        );
      }

      return date;
    }

    const date = calculateWindowDate(
      timeSlot,
      applicableWindow,
      iteration,
    );

    if (!additionalWindows.includes(applicableWindow) && timeSlot.id) {
      updateTimeSlotCache(
        timeSlot,
        { iterations : {
          ...timeSlot.cache?.iterations,
          [iteration] : date.getTime(),
        } },
      );
    }

    return date;
  }, [updateTimeSlotCache]);

  const calculateTime = useCallback((
    timeSlot : TimeSlot,
    iteration : number,
    division? : number,
    options? : {
      additionalWindows? : Window[],
      useWindows? : boolean,
    },
  ) => {
    const baseDate = calculateDate(
      timeSlot,
      iteration,
      options?.additionalWindows,
      options?.useWindows,
    );
    if (!timeSlot.division) return baseDate;

    const time = baseDate.getTime() + ((division ?? 0) * timeSlot.division);
    const maxTime = baseDate.getTime() + calculateDuration(timeSlot, iteration);
    if (time > maxTime) return new Date(maxTime);

    if (!options?.additionalWindows?.length && timeSlot.id) {
      updateTimeSlotCache(
        timeSlot,
        { divisions : {
          ...timeSlot.cache?.divisions,
          [iteration] : {
            ...timeSlot.cache?.divisions?.[iteration],
            [division ?? 0] : time,
          },
        } },
      )
    }
    return new Date(time);
  }, [calculateDuration, calculateDate, updateTimeSlotCache]);

  const calculateDates = useCallback((
    timeSlot : TimeSlot,
    iterations : number[],
    additionalWindows : Window[] = [],
  ) => {
    return iterations.reduce(
      (acc : { [iteration : number] : Date }, iteration) => {
        acc[iteration] = calculateDate(timeSlot, iteration, additionalWindows);
        return acc;
      },
      {},
    );
  }, [calculateDate]);

  const estimateItration = useCallback((
    timeSlot : TimeSlot,
    date : Date,
    additionalWindows : Window[] = [],
  ) => {
    const windows = Object.values(timeSlot.windows).concat(additionalWindows);
    const maxOffset = Math.ceil(windows.reduce(
      (maxOffset, window) => {
        const defaultTime = calculateDate(
          timeSlot,
          window.fromIteration,
          [],
          false,
        ).getTime();
        const adjustedTime = window.start.getTime();
        const offset = Math.abs(adjustedTime - defaultTime);
        return (offset > maxOffset) ? offset : maxOffset;
      },
      0,
    ) / (timeSlot.scale * timeSlot.period));

    const lastIteration = Math.floor(
      (date.getTime() - timeSlot.start.getTime()) /
        (timeSlot.scale * timeSlot.period)
    );
    const nextIteration = lastIteration + 1;

    return generateRange(
      (nextIteration - lastIteration) + (2 * maxOffset) + 1,
      Math.max(lastIteration - maxOffset, 0),
    );
  }, [calculateDate]);

  const findNextIteration = useCallback((
    timeSlot : TimeSlot,
    date : Date,
    additionalWindows : Window[] = [],
  ) => {
    const interationRange = estimateItration(
      timeSlot,
      date,
      additionalWindows,
    );

    for (const i of interationRange) {
      const time = calculateDate(timeSlot, i, additionalWindows);
      if (date <= time) return i;
    }
    return undefined;
  }, [calculateDate, estimateItration]);

  const findNextDivision = useCallback((
    timeSlot : TimeSlot,
    date : Date,
    additionalWindows : Window[] = [],
  ) => {
    const interationRange = estimateItration(
      timeSlot,
      date,
      additionalWindows,
    );

    for (const i of interationRange) {
      for (let j = 0; j <= maxDivisions(timeSlot, i); j++) {
        const time = calculateTime(timeSlot, i, j, { additionalWindows });
        if (date <= time) return { iteration : i, division : j };
      }
    }
    return undefined;
  }, [calculateTime, estimateItration]);

  const getIteration = useCallback((
    timeSlot : TimeSlot,
    date : Date,
    additionalWindows : Window[] = [],
  ) => {
    if (timeSlot.cache?.iterations) {
      const cached = Object.entries(timeSlot.cache.iterations).find(
        d => d[1] === date.getTime()
      );
      if (cached && !isNaN(parseInt(cached[0]))) return parseInt(cached[0]);
    }

    const possibleIteration = findNextIteration(
      timeSlot,
      date,
      additionalWindows,
    );
    if (possibleIteration === undefined) return undefined;

    const possibleDate = calculateDate(
      timeSlot,
      possibleIteration,
      additionalWindows,
    );

    return (possibleDate.getTime() === date.getTime())
      ? possibleIteration
      : undefined;
  }, [calculateDate, findNextIteration]);

  const getIterationDivision = useCallback((
    timeSlot : TimeSlot,
    date : Date,
    options? : {
      additionalWindows? : Window[],
    },
  ) => {
    if (!timeSlot.division) {
      const iteration = getIteration(
        timeSlot,
        date,
        options ? options.additionalWindows : []
      )
      return (iteration !== undefined)
        ? { iteration, division : 0 }
        : undefined;
    };

    if (timeSlot.cache?.divisions) {
      let division : number | undefined;
      const iterationDivisions = Object.entries(timeSlot.cache.divisions).find(
        iter => Object.entries(iter[1]).find(
          div => {
            if (div[1] === date.getTime()) {
              division = parseInt(div[0]);
              return true;
            }
            return false;
          }
        ),
      );
      if (iterationDivisions) {
        const iteration = parseInt(iterationDivisions[0]);
        if (!isNaN(iteration)) return {
          iteration : iteration,
          division : division ?? 0,
        };
      }
    }

    let iteration = findNextIteration(
      timeSlot,
      date,
      options ? options.additionalWindows : [],
    );
    if (iteration === undefined) return undefined;
    iteration -= 1;

    const possibleTime = calculateTime(
      timeSlot,
      iteration,
      0,
      options,
    );

    const offset = date.getTime() - possibleTime.getTime();
    if (offset % timeSlot.division) return undefined;

    const division = offset / timeSlot.division;
    if (
      0 > iteration ||
      0 > division ||
      division >= maxDivisions(timeSlot, iteration)
    ) return undefined;
    return { iteration : iteration, division };
  }, [calculateTime, findNextIteration, getIteration]);

  const validateTimeSlotDay = useCallback((
    timeSlot : TimeSlot,
    day : Date,
    additionalWindows : Window[] = [],
  ) => {
    if (timeSlot.cache?.iterations) {
      const cached = Object.entries(timeSlot.cache.iterations).find(
        d => roundDateTime(new Date(d[1]), timeScales.day).getTime() ===
          roundDateTime(day, timeScales.day).getTime()
      );
      const iteration = cached ? parseInt(cached[0]) : undefined;
      if (iteration && !isNaN(iteration)) return iteration >= 0;
    }

    const possibleIteration = findNextIteration(
      timeSlot,
      roundDateTime(day, timeScales.day),
      additionalWindows,
    );

    if (possibleIteration === undefined) return false;

    const possibleDate = calculateDate(
      timeSlot,
      possibleIteration,
      additionalWindows,
    );

    const roundedDay = roundDateTime(day, timeScales.day);
    const roundedPossible = roundDateTime(possibleDate, timeScales.day);

    return !!(roundedDay.getTime() === roundedPossible.getTime());
  }, [findNextIteration, calculateDate]);

  const validateTimeSlotDateTime = useCallback((
    timeSlot : TimeSlot,
    date : Date,
    additionalWindows : Window[] = [],
  ) => {
    return getIterationDivision(
      timeSlot,
      date,
      { additionalWindows },
    ) !== undefined;
  }, [getIterationDivision]);

  const calculateScheduleMinStep = useCallback(
    (sched : Schedule | Schedule[]) : number => {
      if (!Array.isArray(sched)) return calculateScheduleMinStep([sched]);

      const timeSlots = sched.reduce(
        (acc, schedule) => {
          return acc.concat(Object.values(schedule.timeSlots).filter(
            timeSlot => !acc.some(ts => ts.id === timeSlot.id),
          ));
        },
        [] as TimeSlot[],
      );

      const minStep = timeSlots.reduce((minStep, timeSlot) => {
          return minStep === undefined
            ? calculateMinStep(timeSlot)
            : commonDenominator(minStep, calculateMinStep(timeSlot));
      }, undefined as number | undefined);
      return minStep ?? 1;
    },
    [calculateMinStep],
  );

  const findSelections = useCallback((
    checkSchedules : Schedule[] | Schedule | null,
    from : Date,
    to : Date,
  ) : ScheduleSelection[] => {
    if (!checkSchedules) return [];
    if (!Array.isArray(checkSchedules)) {
      return findSelections([checkSchedules], from, to);
    }

    const timeSlots = checkSchedules.reduce(
      (acc, schedule) => {
        return acc.concat(Object.values(schedule.timeSlots).filter(
          timeSlot => !acc.some(ts => ts.id === timeSlot.id),
        ));
      },
      [] as TimeSlot[],
    );

    return timeSlots.reduce((selections, timeSlot) => {
      let i = findNextIteration(timeSlot, from);
      if (!i) return selections;

      for (let j = 0; j < 10; j++) {
        const nextDate = calculateDate(timeSlot, i);
        if (nextDate > to) return selections;

        selections.push({
          timeSlot : timeSlot,
          iteration : i++,
          division : 0,
        });
      }
      return selections;
    }, [] as ScheduleSelection[]);
  }, [calculateDate, findNextIteration]);

  const checkSchedule = useCallback((
    checkSchedules : Schedule[] | Schedule | null,
    date : Date,
    to? : Date,
  ) : ScheduleSelection | null => {
    if (!checkSchedules) return null;
    if (!Array.isArray(checkSchedules)) {
      return checkSchedule([checkSchedules], date);
    }

    const timeSlots = checkSchedules.reduce(
      (acc, schedule) => {
        return acc.concat(Object.values(schedule.timeSlots).filter(
          timeSlot => !acc.some(ts => ts.id === timeSlot.id),
        ));
      },
      [] as TimeSlot[],
    );

    let iteration : number | undefined;
    let division : number | undefined;
    const timeSlot = timeSlots.find((timeSlot) => {
      const iterationDivision = getIterationDivision(timeSlot, date);
      if (!iterationDivision) return false;
      iteration = iterationDivision.iteration;
      division = iterationDivision.division;
      return true;
    });

    if (!timeSlot || iteration === undefined || division === undefined) {
      return null;
    }

    return {
      timeSlot : timeSlot,
      iteration : iteration,
      division : division,
    };
  }, [getIterationDivision]);

  const checkScheduleDate = useCallback((
    checkSchedules : Schedule[] | Schedule | null,
    date : Date,
  ) : ScheduleSelection | null => {
    if (!checkSchedules) return null;
    if (!Array.isArray(checkSchedules)) {
      return checkScheduleDate([checkSchedules], date);
    }

    const from = roundDateTime(date, timeScales.day);
    const to = shiftLocalDateTime(from, 86400000);
    return findSelections(checkSchedules, from, to)[0] ?? null;
  }, [findSelections]);

  const validateScheduleDay = useCallback((
    validateSchedules : Schedule | Schedule[],
    day : Date,
  ) : boolean => {
    if (!Array.isArray(validateSchedules)) {
      return validateScheduleDay([validateSchedules], day);
    }

    const timeSlots = validateSchedules.reduce(
      (acc, schedule) => {
        return acc.concat(Object.values(schedule.timeSlots).filter(
          timeSlot => !acc.some(ts => ts.id === timeSlot.id),
        ));
      },
      [] as TimeSlot[],
    );
    return timeSlots.some(timeSlot => validateTimeSlotDay(timeSlot, day));
  }, [validateTimeSlotDay])

  const validateScheduleTime = useCallback((
    validateSchedules : Schedule | Schedule[],
    date : Date,
  ) : boolean => {
    if (!Array.isArray(validateSchedules)) {
      return validateScheduleTime([validateSchedules], date) ;
    }

    const timeSlots = validateSchedules.reduce(
      (acc, schedule) => {
        return acc.concat(Object.values(schedule.timeSlots).filter(
          timeSlot => !acc.some(ts => ts.id === timeSlot.id),
        ));
      },
      [] as TimeSlot[],
    );
    return timeSlots.some(timeSlot => validateTimeSlotDateTime(timeSlot, date));
  }, [validateTimeSlotDateTime])

  const getServiceSchedules = useCallback(
    (services : Service[] | Service | null) : Schedule[] => {
      if (!services) return [];
      if (!schedules) return [];

      const serviceArray = Array.isArray(services) ? services : [services];
      return listRecords(schedules).filter((schedule) => {
        return serviceArray && serviceArray.some(s => (
          s.id && schedule.serviceIds?.includes(s.id)
        ));
      });
    },
    [schedules],
  );

  const getScheduleTimeSlots = useCallback(
    (sched : Schedule[] | Schedule | null) : TimeSlot[] => {
      if (!sched) return [];
      if (!timeSlots) return [];

      const scheduleArray = Array.isArray(sched) ? sched : [sched];
      return listRecords(timeSlots).filter((timeSlot) => {
        return scheduleArray && scheduleArray.some(s => (
          Object.keys(s.timeSlots).includes(`${timeSlot.id}`)
        ));
      });
    },
    [timeSlots],
  );

  return {
    schedules,
    timeSlots,
    loaded,
    load,
    createSchedule,
    refreshSchedules,
    refreshSchedule,
    retrieveSchedules,
    retrieveSchedule,
    updateSchedule,
    deleteSchedule,
    addScheduleToService : addSchedule,
    removeScheduleFromService : removeSchedule,
    addServiceToSchedule : addScheduleToService,
    removeServiceFromSchedule : removeScheduleFromService,
    createTimeSlot,
    refreshTimeSlots,
    refreshTimeSlot,
    retrieveTimeSlots,
    retrieveTimeSlot,
    updateTimeSlot,
    deleteTimeSlot,
    addTimeSlotToSchedule,
    removeTimeSlotFromSchedule,
    createWindow,
    calculateMinStep,
    calculateDate,
    calculateTime,
    calculateDuration,
    findNextIteration,
    findNextDivision,
    getIteration,
    getIterationDivision,
    validateTimeSlotDay,
    validateTimeSlotDateTime,
    calculateScheduleMinStep,
    findSelections,
    checkSchedule,
    checkScheduleDate,
    validateScheduleDay,
    validateScheduleTime,
    getServiceSchedules,
    getScheduleTimeSlots,
  };
}

export default useTimeSlots;
