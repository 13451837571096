import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled';

import { ContactInfo } from '#mrktbox/types';
import { useFormContext } from '#mrktbox';

import Input from '#materials/Input';
import Form, { FormWrapper } from '#materials/Form';
import FormError from '#materials/FormError';
import Checkbox from '#materials/Checkbox';
import ButtonStyled from '#materials/ButtonStyled';

const CheckboxSection = styled.div`
  margin : 1rem 0;
`;

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

function formatPhone(phone? : string, format : boolean = true) {
  const digits = phone?.replace(/\D/g, '') ?? '';
  if (digits.length === 10) {
    return format
      ? `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`
      : digits;
  }
  return digits;
}

interface ProfileFormProps {
  contactInfo : ContactInfo;
  error? : string;
  onSubmit? : () => void;
}

function ProfileForm({
  contactInfo : fallback,
  error = '',
  onSubmit,
} : ProfileFormProps) {
  const {
    state,
    dispatch,
    editing,
    setEditing,
    reset,
  } = useFormContext<ContactInfo>();

  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);
  const setNickname = useCallback((nickname : string) => {
    dispatch({ nickname });
  }, [dispatch]);
  const setEmail = useCallback((email : string) => {
    if (!email || emailRegex.test(email)) setEmailValid(true);
    dispatch({ email });
  }, [dispatch]);
  const setPhone = useCallback((phone : string) => {
    if (!phone || formatPhone(phone, false).length === 10) setPhoneValid(true);
    dispatch({ phone : formatPhone(phone, false) });
  }, [dispatch]);
  const setSmsNotifications = useCallback((smsNotifications : boolean) => {
    dispatch({ smsNotifications });
  }, [dispatch]);
  const setEmailNotifications = useCallback((emailNotifications : boolean) => {
    dispatch({ emailNotifications });
  }, [dispatch]);

  const handlePhoneBlur = useCallback(() => {
    setPhoneValid(!state?.phone
      || formatPhone(state.phone, false).length === 10);
  }, [state]);
  const handleEmailBlur = useCallback(() => {
    setEmailValid(!state?.email || emailRegex.test(state.email));
  }, [state]);

  const hancleCancel = useCallback(() => {
    setEditing(false);
    reset();
    setEmailValid(true);
    setPhoneValid(true);
  }, [setEditing, reset]);

  const changed = (
    state?.name !== fallback?.name ||
    state?.nickname !== fallback?.nickname ||
    state?.email !== fallback?.email ||
    state?.phone !== fallback?.phone ||
    state?.smsNotifications !== fallback?.smsNotifications ||
    state?.emailNotifications !== fallback?.emailNotifications
  )

  return (
    <FormWrapper>
      <Form
        error={(
          <FormError errMsg={error} />
        )}
        buttons={editing
          ? (
            <>
              <ButtonStyled
                size={'default'}
                type='submit'
                disabled={!changed
                  || !state
                  || !state.name
                  || !state.phone
                  || !state.email
                  || !emailRegex.test(state.email)
                  || state.phone.length !== 10}
              >
                Update
              </ButtonStyled>
              <ButtonStyled size={'default'} onClick={hancleCancel}>
                Cancel
              </ButtonStyled>
            </>
          ) : (
            <ButtonStyled size={'default'} onClick={() => setEditing(true)}>
              Edit Profile
            </ButtonStyled>
          )
        }
        onSubmit={onSubmit}
      >
        <Input
          label="Name"
          name ="name"
          type="text"
          value={state?.name ?? fallback?.name}
          onChange={setName}
          required={editing}
          disabled={!editing}
        />
        <Input
          label="Nickname"
          name ="nickname"
          type="text"
          value={state?.nickname ?? fallback?.nickname}
          onChange={setNickname}
          disabled={!editing}
        />
        <FormError errMsg={!emailValid ? 'Invalid email' : ''} />
        <Input
          label="Email"
          name ="email"
          type="email"
          value={state?.email ?? fallback?.email}
          onChange={setEmail}
          onBlur={handleEmailBlur}
          required={editing}
          disabled={!editing}
        />
        <FormError errMsg={!phoneValid
          ? 'Phone number must be exactly 10 digits'
          : ''}
        />
        <Input
          label="Phone"
          name="phone"
          type="phone"
          value={formatPhone(
            (state?.phone ?? fallback?.phone),
            !editing,
          )}
          onChange={setPhone}
          onBlur={handlePhoneBlur}
          required={editing}
          disabled={!editing}
        />
        <CheckboxSection>
          <Checkbox
            checked={state?.emailNotifications ?? fallback?.emailNotifications}
            label='Receive Email Notifications'
            onChange={setEmailNotifications}
            disabled={!editing}
          />
          <Checkbox
            checked={state?.smsNotifications ?? fallback?.smsNotifications}
            label='Receive SMS Notifications'
            onChange={setSmsNotifications}
            disabled={!editing}
          />
        </CheckboxSection>
      </Form>
    </FormWrapper>
  );
}

export default ProfileForm;
