import { useCallback } from 'react';

import useReportsAPI, {
  LogEntryQuery
} from '#mrktbox/clerk/hooks/api/useReportsAPI';

import { responseResource } from '#mrktbox/clerk/utils/api';

function useReports() {
  const {
    retrieveLogs,
    retrieveEventReport,
  } = useReportsAPI();

  const getLogs = useCallback(
    async ({
      eventCode,
      starting,
      ending,
      query,
    } : LogEntryQuery) => {
      return await retrieveLogs({
        eventCode,
        starting,
        ending,
        query,
      });
    },
    [retrieveLogs],
  );

  const getEventReport = useCallback(
    async (
      eventCode : string,
      starting : Date,
      ending : Date,
      interval : number,
    ) => {
      return responseResource(await retrieveEventReport(
        eventCode,
        starting,
        ending,
        interval,
      ));
    },
    [retrieveEventReport],
  );

  return {
    retrieveLogs : getLogs,
    retrieveEventReport : getEventReport,
  };
}

export default useReports;
