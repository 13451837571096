import { useCallback, useEffect, useReducer, useState } from 'react';

import {
  ActionType,
  DataReducer,
  DataIndex,
  DataStore,
} from '#mrktbox/clerk/types';
import { actionTypes } from '#mrktbox/clerk/utils/data';

export { actionTypes };
export type { ActionType };

function getDataStore<RT>({
  storageKey,
  deserializer,
} : {
  storageKey : string,
  deserializer? : (data : any) => RT | null,
}) : DataStore<RT> | null {
  const store = localStorage.getItem(storageKey);
  if (store === null) return null;

  const { data, timestamp } = JSON.parse(store);

  return {
    data : deserializer
      ? Object.entries(data).reduce(
        (acc : DataIndex<RT>, [key, value] : [string, any]) => {
          return { ...acc, [key] : deserializer(value) };
        },
        {},
      )
      : data,
    timestamp,
    storageKey,
  };
}

function setDataStore<RT>({
  storageKey,
  data,
  reset,
  resetTimestamp,
  serializer,
} : {
  storageKey : string,
  data : DataIndex<RT>,
  reset? : boolean,
  resetTimestamp? : boolean,
  serializer? : (data : RT | null) => any,
}) {
  const existingStore = getDataStore({ storageKey });

  const store = JSON.stringify({
    data : {
      ...(!reset && (existingStore?.data ?? {})),
      ...serializer
        ? Object.entries(data).reduce((acc, [key, value]) => {
          return { ...acc, [key] : serializer(value) };
        }, {})
        : data,
    },
    timestamp : (resetTimestamp || (existingStore === null))
      ? Date.now()
      : existingStore.timestamp,
  });
  localStorage.setItem(storageKey, store);
}

function reduceData<RT>({
  storageKey,
  serializer,
} : {
  storageKey? : string,
  serializer? : (data : RT | null) => any,
}) {
  return (
    state : DataIndex<RT> | null,
    action : { data : DataIndex<RT>, type? : ActionType },
  ) => {
    let result = state;
    switch (action.type) {
      case actionTypes.set:
        result = {
          ...action.data,
        };
        break;
      case actionTypes.update:
        result = {
          ...state,
          ...action.data,
        };
        break;
      case actionTypes.add:
        result = {
          ...state,
          ...action.data,
        };
        break;
      case actionTypes.remove:
        if (state === null) break;
        result = Object.entries(state).reduce((acc, [key, value]) => {
          if (key in action.data) return acc;
          else return { ...acc, [key] : value };
        }, {});
        break;
      default:
        result = {
          ...state,
          ...action.data,
        };
        break;
    }

    if (storageKey && result) setDataStore({
      storageKey,
      serializer,
      data : result,
      reset : true,
      resetTimestamp : action.type === actionTypes.set
        || action.type === actionTypes.update,
    });
    return result;
  }
}

interface useDataProps {
  storageKey? : string;
  serializer? : (data : any) => any;
  deserializer? : (data : any) => any;
}

function useStorage<RT>({
  storageKey,
  serializer,
  deserializer,
} : useDataProps = {} ) {
  const [loaded, setLoaded] = useState(false);
  const store = (!loaded && storageKey)
    ? getDataStore<RT>({ storageKey, deserializer })
    : null;
  if (store) setLoaded(true);

  const [data, dispatch] = useReducer<DataReducer<RT>>(
    reduceData<RT>({ storageKey, serializer }),
    store?.data ?? null,
  );
  const [lastUpdated, setLastUpdated] = useState<Date | undefined>(
    store?.timestamp ? new Date(store.timestamp) : undefined,
  );

  const clearCache = useCallback(() => {
    if (storageKey) dispatch({ data : {}, type : actionTypes.set });
    else localStorage.clear();
  }, [storageKey]);

  useEffect(() => {
    if (!storageKey) return;

    const store = getDataStore<RT>({ storageKey, deserializer });
    if (store === null) return;

    setLastUpdated(store.timestamp ? new Date(store.timestamp) : undefined);
  }, [storageKey, deserializer, data]);

  return {
    data,
    dispatch,
    lastUpdated,
    clearCache,
  };
}

export default useStorage;
