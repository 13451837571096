import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  DataIndex,
  Currency,
  Assembly,
  Collection,
  Selection,
  Product,
  Tag,
  Adjustment,
  LineItem,
  Order,
  DraftOrder,
  DraftCustomOrder,
  isLineItem,
  isSelection,
} from '#mrktbox/clerk/types';

import OrderContext from '#mrktbox/clerk/context/OrderContext';

import useData, { actionTypes, useLoad } from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useOptionsAPI from '#mrktbox/clerk/hooks/api/useOptionsAPI';

import { addCurrency } from '#mrktbox/clerk/utils/currency';
import { filterIndex } from '#mrktbox/clerk/utils/data';
import {
  serializeDateTime,
  deserializeDateTime,
} from '#mrktbox/clerk/utils/date';

export type AssemblyIndex = DataIndex<Assembly>;
type SelectionReturn = {
  selection : Selection | null;
  orders : DataIndex<Order> | null;
};
type NewSelectionsReturn = {
  selections : DataIndex<Selection>;
  lineItems : DataIndex<LineItem>;
  orders : DataIndex<Order> | null;
};
type SelectionsReturn = {
  selections : DataIndex<Selection> | null;
  orders : DataIndex<Order> | null;
};

const MAX_AGE = 1000 * 60 * 60;

function serializeCollection(collection : Collection) {
  return {
    ...collection,
    starting : collection.starting
      ? serializeDateTime(collection.starting)
      : null,
    ending : collection.ending
      ? serializeDateTime(collection.ending)
      : null,
  };
}

function deserializeCollection(collection : any) : Collection {
  return {
    ...collection,
    starting : collection.starting
      ? deserializeDateTime(collection.starting)
      : null,
    ending : collection.ending
      ? deserializeDateTime(collection.ending)
      : null,
  };
}

const OptionsContext = createContext({
  assemblies : null as DataIndex<Assembly> | null,
  collections : null as DataIndex<Collection> | null,
  selections : null as DataIndex<Selection> | null,
  customOrders : null as DraftCustomOrder[] | null,
  assembliesLoaded : false,
  collectionsLoaded : false,
  selectionsLoaded : false,
  cacheSelections : (selections : DataIndex<Selection>, ) => {},
  load : () => {},
  loadAssemblies : () => {},
  loadCollections : () => {},
  loadSelections : () => {},
  createAssembly : async (assembly : Assembly) => null as Assembly | null,
  refreshAssembly : async (id : number) => null as Assembly | null,
  refreshAssemblies : async () => null as AssemblyIndex | null,
  retrieveAssembly : async (id : number) => null as Assembly | null,
  retrieveAssemblies : async () => null as AssemblyIndex | null,
  updateAssembly : async (assembly : Assembly) => null as Assembly | null,
  deleteAssembly : async (assembly : Assembly) => null as Assembly | null,
  addAssemblyToProduct :
    async (product : Product, assembly : Assembly) => null as Assembly | null,
  removeAssemblyFromProduct :
    async (product : Product, assembly : Assembly) => null as Assembly | null,
  createCollection :
    async (collection : Collection) => null as Collection | null,
  refreshCollection : async (id : number) => null as Collection | null,
  refreshCollections : async () => null as DataIndex<Collection> | null,
  retrieveCollection : async (id : number) => null as Collection | null,
  retrieveCollections : async () => null as DataIndex<Collection> | null,
  updateCollection :
    async (collection : Collection) => null as Collection | null,
  deleteCollection :
    async (collection : Collection) => null as Collection | null,
  addTagToCollection :
    async (collection : Collection, tag : Tag) => null as Collection | null,
  removeTagFromCollection :
    async (collection : Collection, tag : Tag) => null as Collection | null,
  addCollectionToAssembly : async (
    assembly : Assembly,
    collection : Collection
  ) => null as Assembly | null,
  removeCollectionFromAssembly : async (
    assembly : Assembly,
    collection : Collection
  ) => null as Assembly | null,
  createSelection : async (selection : Selection) => null as Selection | null,
  refreshSelection : async (id : number) => null as Selection | null,
  refreshSelections : async () => null as DataIndex<Selection> | null,
  retrieveSelection : async (id : number) => null as Selection | null,
  retrieveSelections : async () => null as DataIndex<Selection> | null,
  updateSelection : async (selection : Selection) => null as Selection | null,
  deleteSelection : async (selection : Selection) => null as Selection | null,
  bulkCreateSelections : async (
    selections : Selection[],
    lineItems? : LineItem[],
  ) => null as {
    selections : DataIndex<Selection>,
    lineItems : DataIndex<LineItem>,
  } | null,
  bulkRetrieveSelections : async (selectionIds : number[]) =>
    null as DataIndex<Selection> | null,
  bulkUpdateSelections : async (selections : Selection[], options? : {
    lineItems? : LineItem[],
    deleteSelections? : Selection[],
  }) => null as DataIndex<Selection> | null,
  bulkDeleteSelections : async (selections : Selection[]) =>
    null as DataIndex<Selection> | null,
  customiseOrder : (
    order : DraftOrder,
    selected? : DataIndex<Selection> | null,
  ) => ({ ...order, selections : {} }) as DraftCustomOrder,
  calculateLinePrice : (
    line : LineItem | Selection,
    order? : DraftCustomOrder | null,
    options? : {
      quantity? : number,
      selections? : Selection[],
      adjustments? : Adjustment[],
    },
  ) => null as Currency | null,
});

interface OptionsProviderProps {
  children : React.ReactNode;
}

export function OptionsProvider({ children } : OptionsProviderProps) {
  const {
    lineItems,
    cacheLineItems,
    cacheOrders,
    buildOrders,
    calculateLinePrice,
  } = useContext(OrderContext);

  const {
    createAssembly,
    retrieveAssembly,
    retrieveAssemblies,
    updateAssembly,
    deleteAssembly,
    addAssemblyToProduct,
    removeAssemblyFromProduct,
    createCollection,
    retrieveCollection,
    retrieveCollections,
    updateCollection,
    deleteCollection,
    addTagToCollection,
    removeTagFromCollection,
    addCollectionToAssembly,
    removeCollectionFromAssembly,
    createSelection,
    retrieveSelection,
    retrieveSelections,
    updateSelection,
    deleteSelection,
    bulkCreateSelections,
    bulkRetrieveSelections,
    bulkUpdateSelections,
    bulkDeleteSelections,
  } = useOptionsAPI();

  const [customOrders, setCustomOrders] = useState<DraftCustomOrder[]>([]);

  const {
    data : assemblies,
    dispatch : dispatchAssemblies,
    lastUpdated : assembliesLastUpdated,
  } = useData<Assembly>({ storageKey: 'assemblies' });
  const {
    data : collections,
    dispatch : dispatchCollections,
    lastUpdated : collectionsLastUpdated,
  } = useData<Collection>({
    storageKey: 'collections',
    serializer : serializeCollection,
    deserializer : deserializeCollection,
  });
  const {
    data : allSelections,
    dispatch : dispatchSelections,
    lastUpdated : selectionsLastUpdated,
  } = useData<Selection>({ storageKey: 'selections' });

  const [
    selections,
    setSelections,
  ] = useState<DataIndex<Selection> | null>(null);

  const assembliesStale = assembliesLastUpdated !== undefined &&
    (new Date().getTime() - assembliesLastUpdated.getTime()) > MAX_AGE;
  const collectionsStale = collectionsLastUpdated !== undefined &&
    (new Date().getTime() - collectionsLastUpdated.getTime()) > MAX_AGE;
  const selectionsStale = selectionsLastUpdated !== undefined &&
    (new Date().getTime() - selectionsLastUpdated.getTime()) > MAX_AGE;

  const cacheSelections = useCallback(
    <T extends Selection>(tags : DataIndex<T> | T | null) => {
      if (!tags) return null;
      const index = isSelection(tags)
        ? (tags.id ? { [tags.id] : tags } : {})
        : tags;

      dispatchSelections({
        type : actionTypes.add,
        data : index,
      });
      return index;
    },
    [dispatchSelections],
  );

  const parseSelection = useCallback((response : SelectionReturn) => {
    if (response.orders) cacheOrders(response.orders);
    if (!response.selection?.id) return null;
    return { [response.selection.id] : response.selection };
  }, [cacheOrders]);
  const parseNewSelections = useCallback((response : NewSelectionsReturn) => {
    if (response.orders) cacheOrders(response.orders);
    if (!response.selections) return null;
    cacheLineItems(response.lineItems);
    return response.selections;
  }, [cacheLineItems, cacheOrders]);
  const parseSelections = useCallback((response : SelectionsReturn) => {
    if (response.orders) cacheOrders(response.orders);
    if (!response.selections) return null;
    return response.selections;
  }, [cacheOrders]);
  const filterSelection = useCallback((response : SelectionReturn) => {
    return response.selection;
  }, []);
  const filterNewSelections = useCallback((response : NewSelectionsReturn) => {
    return {
      lineItems : response.lineItems,
      selections : response.selections,
    };
  }, []);
  const filterSelections = useCallback((response : SelectionsReturn) => {
    return response.selections;
  }, []);

  const newAssembly = useCache({
    process : createAssembly,
    dispatch : dispatchAssemblies,
  });
  const refreshAssemblies = useCache({
    process : retrieveAssemblies,
    dispatch : dispatchAssemblies,
    refresh : true,
    isLoader : true,
  });
  const refreshAssembly = useCache({
    process : retrieveAssembly,
    dispatch : dispatchAssemblies,
    isLoader : true,
  });
  const getAssemblies = useCache({
    process : retrieveAssemblies,
    dispatch : dispatchAssemblies,
    data : assemblies,
    stale : assembliesStale,
    refresh : true,
    isLoader : true,
  });
  const getAssembly = useCache({
    process : retrieveAssembly,
    dispatch : dispatchAssemblies,
    data : assemblies,
    stale : assembliesStale,
    isLoader : true,
  });
  const amendAssembly = useCache({
    process : updateAssembly,
    dispatch : dispatchAssemblies,
  });
  const removeAssembly = useCache({
    process : deleteAssembly,
    dispatch : dispatchAssemblies,
    drop : true,
  });

  const connectAssemblyToProduct = useCache({
    process : addAssemblyToProduct,
    dispatch : dispatchAssemblies,
  });
  const disconnectAssemblyFromProduct = useCache({
    process : removeAssemblyFromProduct,
    dispatch : dispatchAssemblies,
  });

  const newCollection = useCache({
    process : createCollection,
    dispatch : dispatchCollections,
  });
  const refreshCollections = useCache({
    process : retrieveCollections,
    dispatch : dispatchCollections,
    refresh : true,
    isLoader : true,
  });
  const refreshCollection = useCache({
    process : retrieveCollection,
    dispatch : dispatchCollections,
    isLoader : true,
  });
  const getCollections = useCache({
    process : retrieveCollections,
    dispatch : dispatchCollections,
    data : collections,
    stale : collectionsStale,
    refresh : true,
    isLoader : true,
  });
  const getCollection = useCache({
    process : retrieveCollection,
    dispatch : dispatchCollections,
    data : collections,
    stale : collectionsStale,
    isLoader : true,
  });
  const amendCollection = useCache({
    process : updateCollection,
    dispatch : dispatchCollections,
  });
  const removeCollection = useCache({
    process : deleteCollection,
    dispatch : dispatchCollections,
    drop : true,
  });

  const connectTagToCollection = useCache({
    process : addTagToCollection,
    dispatch : dispatchCollections,
  });
  const disconnectTagFromCollection = useCache({
    process : removeTagFromCollection,
    dispatch : dispatchCollections,
  });

  const connectCollectionToAssembly = useCache({
    process : addCollectionToAssembly,
    dispatch : dispatchAssemblies,
  });
  const disconnectCollectionFromAssembly = useCache({
    process : removeCollectionFromAssembly,
    dispatch : dispatchAssemblies,
  });

  const newSelection = useCache({
    process : createSelection,
    parser : parseSelection,
    filter : filterSelection,
    dispatch : dispatchSelections,
  });
  const refreshSelections = useCache({
    process : retrieveSelections,
    dispatch : dispatchSelections,
    refresh : true,
    isLoader : true,
  });
  const refreshSelection = useCache({
    process : retrieveSelection,
    dispatch : dispatchSelections,
    isLoader : true,
  });
  const getSelections = useCache({
    process : retrieveSelections,
    dispatch : dispatchSelections,
    data : allSelections,
    stale : selectionsStale,
    refresh : true,
    isLoader : true,
  });
  const getSelection = useCache({
    process : retrieveSelection,
    dispatch : dispatchSelections,
    data : allSelections,
    stale : selectionsStale,
    isLoader : true,
  });
  const amendSelection = useCache({
    process : updateSelection,
    parser : parseSelection,
    filter : filterSelection,
    dispatch : dispatchSelections,
  });
  const removeSelection = useCache({
    process : deleteSelection,
    parser : parseSelection,
    filter : filterSelection,
    dispatch : dispatchSelections,
    drop : true,
  });

  const bulkNewSelections = useCache({
    process : bulkCreateSelections,
    parser : parseNewSelections,
    filter : filterNewSelections,
    dispatch : dispatchSelections,
  });
  const bulkRefreshSelections = useCache({
    process : bulkRetrieveSelections,
    dispatch : dispatchSelections,
    isLoader : true,
  });
  const bulkAmendSelections = useCache({
    process : bulkUpdateSelections,
    parser : parseNewSelections,
    filter : filterSelections,
    dispatch : dispatchSelections,
  });
  const bulkRemoveSelections = useCache({
    process : bulkDeleteSelections,
    parser : parseSelections,
    filter : filterSelections,
    dispatch : dispatchSelections,
    drop : true,
  });

  const { load : loadAssemblies, loaded : assembliesLoaded } = useLoad({
    data : assemblies,
    loader : refreshAssemblies,
  });
  const { load : loadCollections, loaded : collectionsLoaded } = useLoad({
    data : collections,
    loader : refreshCollections,
  });
  const { load : loadSelections, loaded : selectionsLoaded } = useLoad({
    data : allSelections,
    loader : refreshSelections,
  });

  const load = useCallback(async () => {
    loadAssemblies();
    loadCollections();
    loadSelections();
  }, [loadAssemblies, loadCollections, loadSelections]);

  const customiseOrder = useCallback((
    order : DraftOrder,
    selected = selections,
  ) => {
    const customOrder : DraftCustomOrder = {
      ...order,
      selections : selected === null
        ? {}
        : Object.values(selected).filter(
          s => Object.keys(order.lineItems).includes(`${s?.lineItemId}`),
        ).reduce((acc, s) => ({ ...acc, [s?.id || NaN] : s }), {}),
    };
    return customOrder;
  }, [selections]);

  const buildCustomOrders = useCallback(async (
    items = lineItems,
    selected = selections,
  ) => {
    if (!items) return [];
    const orders = await buildOrders(items);
    if (!orders) return [];

    const custom = orders.map(order => customiseOrder(order, selected));
    return custom;
  }, [buildOrders, lineItems, selections, customiseOrder]);

  const calculateCustomLinePrice = useCallback((
    line : LineItem | Selection,
    order : DraftCustomOrder | null = null,
    options : {
      quantity? : number,
      selections? : Selection[],
      adjustments? : Adjustment[],
    } = {},
  ) : Currency | null => {
    if (isSelection(line)) {
      const assembly = assemblies?.[line.assemblyId];
      if (assembly?.complimentary) return null;
    }

    const selections = isLineItem(line)
      ? options?.selections ?? (order?.selections
        ? Object.values(order.selections).filter(
          (s) => s.lineItemId === line.id,
        )
        : [])
      : [];

    const lineItemId = isLineItem(line) ? line.id : line.lineItemId;
    const linePrice = calculateLinePrice(
      { ...line, id : lineItemId },
      order,
      options,
    );
    if (!linePrice) return null;

    const selectionPrice = selections.reduce((
      total : Currency,
      selection : Selection,
    ) => {
      const assembly = assemblies?.[selection.assemblyId];
      if (assembly?.complimentary) return total;

      const price = calculateCustomLinePrice(
        selection,
        order,
        { quantity : line.quantity }
      );
      if (!price) return total;
      return addCurrency(total, price);
    }, linePrice);

    return selectionPrice;
  }, [assemblies, calculateLinePrice]);

  useEffect(() => {
    if (!allSelections) return;
    setSelections(filterIndex(
      allSelections,
      undefined,
      { dropDeleted : true }
    ));
  }, [allSelections]);

  useEffect(() => {
    buildCustomOrders().then(setCustomOrders);
  }, [buildCustomOrders]);

  const context = {
    assemblies,
    collections,
    selections,
    customOrders,
    assembliesLoaded,
    collectionsLoaded,
    selectionsLoaded,
    cacheSelections,
    load,
    loadAssemblies,
    loadCollections,
    loadSelections,
    createAssembly : newAssembly,
    refreshAssemblies,
    refreshAssembly,
    retrieveAssemblies : getAssemblies,
    retrieveAssembly : getAssembly,
    updateAssembly : amendAssembly,
    deleteAssembly : removeAssembly,
    addAssemblyToProduct : connectAssemblyToProduct,
    removeAssemblyFromProduct : disconnectAssemblyFromProduct,
    createCollection : newCollection,
    refreshCollections,
    refreshCollection,
    retrieveCollections : getCollections,
    retrieveCollection : getCollection,
    updateCollection : amendCollection,
    deleteCollection : removeCollection,
    addTagToCollection : connectTagToCollection,
    removeTagFromCollection : disconnectTagFromCollection,
    addCollectionToAssembly : connectCollectionToAssembly,
    removeCollectionFromAssembly : disconnectCollectionFromAssembly,
    createSelection : newSelection,
    refreshSelections,
    refreshSelection,
    retrieveSelections : getSelections,
    retrieveSelection : getSelection,
    updateSelection : amendSelection,
    deleteSelection : removeSelection,
    bulkCreateSelections : bulkNewSelections,
    bulkRetrieveSelections : bulkRefreshSelections,
    bulkUpdateSelections : bulkAmendSelections,
    bulkDeleteSelections : bulkRemoveSelections,
    customiseOrder,
    calculateLinePrice : calculateCustomLinePrice,
  };

  return (
    <OptionsContext.Provider value={context}>
      { children }
    </OptionsContext.Provider>
  );
}

export default OptionsContext;
