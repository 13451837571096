import React from 'react';

import { Subtotal } from '#mrktbox/types';
import { useAdjustments } from '#mrktbox';

import AdjustmentSubtotal from '#components/cart/Subtotal';

interface TaxProps {
  total : Subtotal;
}

function Adjustment({ total } : TaxProps) {
  const { adjustments } = useAdjustments();

  const adjustment = total.adjustmentId
    ? (adjustments?.[total.adjustmentId] ?? null)
    : null;

  return (
    <AdjustmentSubtotal
      label={adjustment?.name ?? (total.total.amount > 0 ? 'Fee' : 'Deal') }
      subtotal={total.total}
      bold
    />
  );
}

export default Adjustment;
