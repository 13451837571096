import { useCallback } from 'react';

import { Customer, CreditCard } from '#mrktbox/clerk/types';

import {
  retrieveCreditCards as retrieveCreditCardsAPI,
  retrieveCreditCard as retrieveCreditCardAPI,
  createCreditCard as createCreditCardAPI,
  deleteCreditCard as deleteCreditCardAPI,
} from '#mrktbox/clerk/api/mrktbox/accounts';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useAccountsAPI() {
  const { processRequest } = useAPI();

  const retrieveCreditCards = useCallback(async () => {
    return await processRequest(
      retrieveCreditCardsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveCreditCard = useCallback(
    async (creditCardId : number) => {
      return await processRequest(
        retrieveCreditCardAPI,
        { cardId : creditCardId },
      )
    },
    [processRequest],
  );

  const createCreditCard = useCallback(
    async (
      creditCard : CreditCard,
      customer : Customer,
      validation? : {
        token : string,
        postal : string,
      },
    ) => {
      return await processRequest(
        createCreditCardAPI,
        {
          creditCard,
          customer,
          token : validation?.token || '',
          postal : validation?.postal || '',
        },
      )
    },
    [processRequest],
  );

  const deleteCreditCard = useCallback(
    async (creditCard : CreditCard) => {
      return await processRequest(
        deleteCreditCardAPI,
        { creditCard },
      )
    },
    [processRequest],
  );

  return {
    retrieveCreditCards,
    retrieveCreditCard,
    createCreditCard,
    deleteCreditCard,
  };
}

export default useAccountsAPI;
