import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from '@emotion/styled';

import { Address, Location } from '#mrktbox/types';
import { useAddresses } from '#mrktbox';
import { formatDateTime, formats } from '#mrktbox/utils';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useMap from '#hooks/useMap';
import useRequests from '#hooks/useRequests';

import { BgImage } from '#materials/BackgroundImage';
import Heading from '#materials/Heading';
import Box from '#materials/Box';
import ButtonStyled from '#materials/ButtonStyled';
import Checkmark from '#materials/Checkmark';
import { MapPin } from '#materials/icons';

import LocationAction from '#components/orders/LocationAction';

import { scrollToId } from '#utils/scroll';
import { remToPx } from '#utils/style';

interface Style { theme? : Theme; }

interface LocationViewStyle extends Style {
  hasBox? : boolean;
  isMenu? : boolean;
  isActive? : boolean;
}

interface LocationContentStyle extends LocationViewStyle {
  showImage? : boolean;
}

const LocationView = styled(Box)<LocationViewStyle>`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 15rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    ${(props) => (props.hasBox ? 'padding: 1.5rem' : '')};
    min-height: 10rem;
  }
`;

const LocationImage = styled(BgImage)<Style>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 24rem;
  background-color: ${(props) => props.theme.bgColours.tertiary};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

const LocationContent = styled.div<LocationContentStyle>`
  padding: 0 ${(props) => (props.showImage ? `24rem` : null)} 0 0;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }

  > div {
    padding: ${(props) =>
      props.hasBox ? '2rem 2.5rem 2.5rem' : '0 2.5rem 0 0'};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 0;
    }
  }
`;

const LocationHeader = styled.div<Style>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  & > * {
    display: block;
  }
`;

const LocationTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }
`;

const LocationDescription = styled(Heading)`
  display: block;
  margin: 0.75rem 0 0;
  white-space: pre-line;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  line-height: ${(props) => props.theme.fonts.body.lineHeight};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`;

const LocationCheckmark = styled.div`
  margin: 0 0 0 1rem;
`;

const LocationFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LocationFlexContent = styled.div`
  flex: 1 1 auto;
`;

const LocationImageMobile = styled(BgImage)<Style>`
  border-radius: ${(props) => props.theme.border.radiusSmall};
  flex: 0 0 15rem;
  width: 15rem;
  height: 10rem;
  margin: 0 0 0 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex: 0 0 9rem;
    width: 9rem;
    height: 6rem;
    margin: 0;
  }
`;

const LocationActions = styled.div`
  label: LocationActions;

  a,
  button {
    display: block;
    width: 100%;
    text-align: left;
    text-decoration: none;
  }
`;

const LocationButtons = styled.div<Style>`
  margin-top: 1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 1.5rem;
  }

  button {
    margin-bottom: 0;
  }
`;

interface LocationCardProps {
  location : Location;
  showImage? : boolean;
  isActive? : boolean;
  onSelect? : () => void;
  disabled? : boolean;
}

function LocationCard({
  location,
  showImage,
  isActive = false,
  disabled = false,
  onSelect,
} : LocationCardProps) {
  const { theme } = useConfig();
  const { proposeTime } = useRequests();
  const { retrieveAddress } = useAddresses();
  const { setPin, removePin } = useMap();

  const [address, setAddress] = useState<Address | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [pinned, setPinned] = useState<string | null>(null);

  const retrieve = useCallback(async () => {
    if (!location.addressId) {
      setAddress(null);
      return;
    }

    const address = await retrieveAddress(location.addressId);
    setAddress(address);
  }, [location.addressId, retrieveAddress]);

  useEffect(() => { retrieve(); }, [retrieve]);

  useEffect(() => {
    if (pinned || !address || !address.latitude || !address.longitude) return;
    const pin = setPin(
      address.latitude,
      address.longitude,
      {
        onClick : location.id
          ? (() => scrollToId(
            `location-${location.id}`,
            { offset : remToPx(theme.layout.navHeight) * 1.2 },
          ))
          : undefined
      }
    );

    if (!pin) return;
    setPinned(pin.key);
  }, [location, pinned, address, setPin, removePin, theme]);

  useEffect(() => { return () => {
    if (!pinned) return;
    removePin(pinned);
  } }, [pinned, removePin]);

  useEffect(() => {
    const image = Object.values(location.images ?? {})[0];
    if (!image || !Object.keys(image.variants).length) return;

    if ('card' in image.variants) setImageUrl(image.variants.card);
    else setImageUrl(Object.values(image.variants)[0]);
  }, [location]);

  const proposedTime = proposeTime({ location });
  const nextAvailable = proposedTime?.time
    ? formatDateTime(proposedTime.time, formats.easy)
    : '';

  const bgImage = imageUrl || process.env.REACT_APP_DEFAULT_LOCATION_IMAGE;
  const bgStyle = { backgroundImage: `url(${bgImage}` };

  return (
    <LocationView
      hasBox={true}
      isActive={isActive}
    >
      { (showImage && !isMobileOnly) && (
        <LocationImage style={bgStyle}>&nbsp;</LocationImage>
      ) }
      <LocationContent
        showImage={showImage && !isMobileOnly}
        hasBox={true}
      >
        <div>
          <LocationFlex>
            <LocationFlexContent>
              <LocationHeader>
                <LocationTitle as="p" className="title">
                  { location.name }
                </LocationTitle>
                {isActive && (
                  <LocationCheckmark>
                    <Checkmark />
                  </LocationCheckmark>
                )}
              </LocationHeader>
              <LocationActions>
                <LocationAction
                  icon={(<MapPin />)}
                  text={(<span>{ address?.description }</span>)}
                />
                { nextAvailable && (
                  <LocationDescription>
                    Next available pickup { nextAvailable }
                  </LocationDescription>
                ) }
                { location.description && (
                  <LocationDescription>
                    { location.description }
                  </LocationDescription>
                ) }
              </LocationActions>
            </LocationFlexContent>
            {showImage && isMobileOnly && (
              <LocationImageMobile style={bgStyle}>
                &nbsp;
              </LocationImageMobile>
            )}
          </LocationFlex>
          <LocationButtons>
            <ButtonStyled
              onClick={onSelect}
              disabled={disabled || !proposedTime.selection}
              size="small"
              colour="primary"
            >
              { proposedTime.selection
                ? 'Order From Here'
                : 'No Times Available'
              }
            </ButtonStyled>
          </LocationButtons>
        </div>
      </LocationContent>
    </LocationView>
  );
}

export default LocationCard;
