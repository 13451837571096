import {
  LogEntry,
  EventReport,
  isLogEntry,
  isFrequencyReport,
} from "#mrktbox/clerk/types";

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

import {
  formats,
  formatDateTime,
  parseDateTime,
} from "#mrktbox/clerk/utils/date";

const LOG_PATH = "reports/logs/";
const EVENTS_PATH = "reports/events/";

export interface LogEntryQuery {
  eventCode? : string;
  starting? : Date;
  ending? : Date;
  query? : {
    [key : string] : string | number | boolean | null;
  }
}

function parseLogEntry(entry: any): LogEntry {
  const nativeEntry = { ...entry };
  if (entry.timestamp) nativeEntry.timestamp = parseDateTime(entry.timestamp);

  if (!isLogEntry(nativeEntry)) {
    throw new TypeError("Log entry is not a log entry");
  }
  return nativeEntry;
}

function parseLogEntries(entries: any) {
  const parsedEntries: { [id: number]: LogEntry } = {};
  for (const entryId in entries) {
    if (typeof entryId !== "string")
      throw new TypeError("Log entry id is not a string");

    const id = parseInt(entryId);
    if (isNaN(id)) throw new TypeError("Log entry id is not a number");

    parsedEntries[id] = parseLogEntry(entries[id]);
  }

  return parsedEntries;
}

function parseEventReport(report: any): EventReport {
  if (!isFrequencyReport(report)) {
    throw new TypeError("Event report is not a frequency report");
  }
  return report;
}

export async function retrieveLogs({
  eventCode,
  starting,
  ending,
  query,
} : LogEntryQuery = {}, options? : RequestOptions) {
  const response = await request(
    getUrl(
      LOG_PATH,
      {
        ...(eventCode && { eventCode }),
        ...(starting && { starting : formatDateTime(starting) }),
        ...(ending && { ending : formatDateTime(ending) }),
        ...(query && { ...query }),
      }
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseLogEntries(response.logEntries);
  } catch {
    throw new DeserializationError("Could not parse log list", response);
  }
}

export async function retrieveEventReport({
  eventCode,
  starting,
  ending,
  interval,
} : {
  eventCode : string;
  starting : Date;
  ending : Date;
  interval : number;
}, options? : RequestOptions) {
  const response = await request(
    getUrl(
      EVENTS_PATH,
      {
        eventCode,
        starting : formatDateTime(starting, formats.iso),
        ending : formatDateTime(ending, formats.iso),
        interval : Math.floor(interval / 1000).toString(),
      }
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseEventReport(response.eventReport);
  } catch {
    throw new DeserializationError("Could not parse event report", response);
  }
}
