import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { ServiceChannel } from '#mrktbox/types';
import { useNavigation, useSubscriptions } from '#mrktbox';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import { AlertCircle } from '#materials/icons';
import Message from '#materials/Message';

import OrderTypeLink from '#components/orders/OrderTypeLink';

interface Style { theme? : Theme; }
interface OrderTypesViewStyle extends Style {
  showDesc? : boolean;
  isMobile? : boolean;
}

const OrderTypesView = styled.div<OrderTypesViewStyle>`

  & > div:first-of-type button {
    ${(props) =>
      !props.showDesc ? `height: ${props.isMobile ? '5.6rem' : '6rem'};` : ``}
  }
`;

const OrderTypesLinks = styled.div<Style>`
  margin: 0 auto;
`;

interface OrderTypesProps {
  serviceChannels : ServiceChannel[];
}

function OrderTypes({ serviceChannels } : OrderTypesProps) {
  const { navigate } = useNavigation();
  const { isServiceChannelSubscribable } = useSubscriptions();
  const { checkChannelAvailability } = useCatalogue();
  const {
    waiting,
    serviceChannel,
    currentOrder,
    setServiceChannel,
  } = useRequests();

  const handleClick = useCallback((channel : ServiceChannel) => async () => {
    if (channel === serviceChannel) {
      navigate('/locations/');
      return;
    }

    const success = await setServiceChannel(channel, { moveItems : true });
    if (success) navigate('/locations/');
  }, [serviceChannel, setServiceChannel, navigate]);

  const hasSubscription = currentOrder
    ? !!Object.values(currentOrder.subscriptions).length
    : false;

  return (
    <>
      { serviceChannels.length ? (
        <OrderTypesView showDesc isMobile={isMobile}>
          <OrderTypesLinks>
            { serviceChannels.map((channel, index) => {
              const available = checkChannelAvailability(channel);
              if (!channel.visible) return null;
              const subscribable = isServiceChannelSubscribable(channel);
              const descriptionOverride = (hasSubscription && !subscribable)
                ? 'Not available for subscription orders.'
                : (available
                  ? undefined
                  : ('Some selected products are unavailable '
                    + 'for this order type.'));
              return (
                <OrderTypeLink
                  key={channel.id ?? `channel-${index}`}
                  channel={channel}
                  description={descriptionOverride}
                  icon={available ? undefined : <AlertCircle />}
                  onClick={handleClick(channel)}
                  disabled={waiting || (hasSubscription && !subscribable)}
                  index={index}
                />
              );
            }) }
          </OrderTypesLinks>
        </OrderTypesView>
      ) : (
        <Message color="error">
          This brand is not currently accepting online orders.
        </Message>
      )}
    </>
  );
}

export default OrderTypes;
