import React from 'react';
import styled from '@emotion/styled';

import Tag from '#materials/Tag';
import { CheckCircle, Refresh } from '#materials/icons';

const OrderTagView = styled.div`
  position: absolute;
  top: -1.1rem;
  right: 1.5rem;
`;

interface OrderTagProps {
  status? : 'pending'
    | 'confirmed'
    | 'inProgress'
    | 'ready'
    | 'fulfilled'
    | 'cancelled'
    | 'draft';
  subscription? : boolean;
}

const OrderTag = ({ status, subscription } : OrderTagProps) => {
  const tag = status === 'draft'
    ? { text : 'Draft' }
    : (status !== 'fulfilled'
      ? status === 'cancelled' ? {
        text : 'Cancelled',
        colour : 'error' as const,
      } : {
        text : 'Coming up',
        icon : <CheckCircle />,
        colour : 'success' as const,
      } : null);

  if (tag === null) return null

  return (
    <OrderTagView>
      <Tag
        text={tag.text}
        icon={subscription
          ? <Refresh />
          : tag.icon
        }
        colour={tag.colour}
        bgColour={tag.colour}
      />
    </OrderTagView>
  );
}

export default OrderTag;
