
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';

import { Currency, Product, Tag } from '#mrktbox/types';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useCatalogue from '#hooks/useCatalogue';

import Heading from '#materials/Heading';
import Count from '#materials/Count';
import { X } from '#materials/icons'

import ProductImage from '#components/products/ProductImage';
import ProductPriceCals from '#components/products/ProductPriceCals';
import ProductTags from '#components/products/ProductTags';

interface Style { theme? : Theme; }

const ProductOptionSquareView = styled.div<Style>`
  position: relative;
  width: 25%;
  padding: 0 0.6rem;
  margin: 0 0 1.2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 33.33333%;
  }
`;

const ProductOptionSquareCount = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.8rem;
  right: -0.2rem;
`;

const ProductOptionSquareRemove = styled.button`
  position: absolute;
  width: 4.4rem;
  height: 4.4rem;
  z-index: 2;
  top: -0.8rem;
  left: -0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProductOptionSquareRemoveIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProductOptionSquareButton = styled.button<Style>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0 0.8rem;
  border-style: solid;
  border-width: ${(props) => props.theme.cards.modifier.borderWidth};
  border-color: ${(props) => props.theme.cards.modifier.borderColor};
  border-radius: ${(props) => props.theme.cards.modifier.borderRadius};
  background-color: ${(props) => props.theme.cards.modifier.bgColor};
  box-shadow: ${(props) => props.theme.cards.modifier.boxShadow};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const ProductOptionSquareImageContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 6rem;
  padding: 0 1.5rem;
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductOptionImageView = styled.div<Style>`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.bgColours.tertiary};
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 6rem;
    height: 6rem;
  }
`;

const ProductOptionSquareText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.3rem;
`;

const ProductOptionSquareName = styled(Heading)``;

interface ProductOptionsSquare {
  product : Product;
  price? : Currency;
  count : number;
  disabled? : boolean;
  addProduct : () => void;
  removeProduct : () => void;
}

function ProductOptionSquare({
  product,
  price,
  count,
  disabled = false,
  addProduct,
  removeProduct,
} : ProductOptionsSquare) {
  const { theme } = useConfig();
  const { getProductTags } = useCatalogue();

  const [tags, setTags] = useState<Tag[]>(getProductTags(product));

  useEffect(() => {
    setTags(getProductTags(product));
  }, [product, getProductTags]);

  return (
    <ProductOptionSquareView>
      { count > 0 && (
        <>
          <ProductOptionSquareCount key={count}>
            <Count count={count} />
          </ProductOptionSquareCount>
          <ProductOptionSquareRemove onClick={removeProduct}>
            <ProductOptionSquareRemoveIcon>
              <X colour={theme.colours.error} size={20} strokeWidth={2} />
            </ProductOptionSquareRemoveIcon>
          </ProductOptionSquareRemove>
        </>
      )}
      <ProductOptionSquareButton onClick={addProduct} disabled={disabled}>
        <ProductOptionSquareImageContainer>
          <ProductOptionImageView>
            <ProductImage product={product} variant="thumbnail" />
          </ProductOptionImageView>
        </ProductOptionSquareImageContainer>
        <ProductOptionSquareText>
          <ProductOptionSquareName size="xSmall">
            { product.name }
          </ProductOptionSquareName>
          <ProductPriceCals
            price={price}
            size="xSmall"
            style={{ marginTop: '0.5rem' }}
          />
          <ProductTags
            tags={tags}
            style={{ marginTop: '0.5rem' }}
          />
        </ProductOptionSquareText>
      </ProductOptionSquareButton>
    </ProductOptionSquareView>
  );
}

export default ProductOptionSquare;
