import { useCallback } from 'react';

import {
  LogEntryQuery,
  retrieveLogs as retrieveLogsAPI,
  retrieveEventReport as retrieveEventReportAPI,
} from '#mrktbox/clerk/api/mrktbox/reports';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

export type { LogEntryQuery } from '#mrktbox/clerk/api/mrktbox/reports';

function useReportsAPI() {
  const { processRequest, processRequestWithReturns } = useAPI();

  const retrieveLogs = useCallback(async (query : LogEntryQuery) => {
    return await processRequest(
      retrieveLogsAPI,
      query,
    )
  }, [processRequest]);

  const retrieveEventReport = useCallback(async (
    eventCode : string,
    starting : Date,
    ending : Date,
    interval : number,
  ) => {
    return await processRequestWithReturns(
      retrieveEventReportAPI,
      {
        eventCode,
        starting,
        ending,
        interval,
      },
    );
  }, [processRequestWithReturns]);

  return {
    retrieveLogs,
    retrieveEventReport,
  };
}

export default useReportsAPI;
