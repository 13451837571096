import React, { useCallback, useState } from 'react';

import { ContactInfo } from '#mrktbox/types';
import { FormProvider, useFormContext, useCustomers } from '#mrktbox';

import ProfileForm from '#components/profile/ProfileForm';

interface ProfileFormProps {
  contactInfo : ContactInfo;
}

function ProfileControl({ contactInfo : fallback } : ProfileFormProps) {
  const { updateContactInfo } = useCustomers();

  const { state : contactInfo, setEditing } = useFormContext<ContactInfo>();

  const [error, setError] = useState('');

  const handleSave = useCallback(async () => {
    if (!contactInfo) return;
    const contact = await updateContactInfo({
      ...contactInfo,
      nickname : contactInfo.nickname || contactInfo.name,
    });
    if (contact) {
      setEditing(false);
      setError('');
    } else {
      setError(
        'Something went wrong. Please try again, or contact us for help.'
      );
    }
  }, [contactInfo, updateContactInfo, setEditing]);

  return (
    <ProfileForm contactInfo={fallback} onSubmit={handleSave} error={error} />
  );
}

function Profile({ contactInfo } : ProfileFormProps) {

  return (
    <FormProvider init={contactInfo} editingInit={false}>
      <ProfileControl contactInfo={contactInfo} />
    </FormProvider>
  );
}

export default Profile;
