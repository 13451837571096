import React, { useCallback, useEffect, useState } from 'react';

import { Location } from '#mrktbox/types';
import { useNavigation, useSubscriptions } from '#mrktbox';

import useModal from '#hooks/useModal';
import useRequests from '#hooks/useRequests';

import LocationsList from '#components/orders/Locations';
import SubscriptionChange from '#components/orders/SubscriptionChange';

import { formatDateTime, formats } from '#mrktbox/utils';

function Locations() {
  const { navigate } = useNavigation();
  const { openModal, closeModal } = useModal();
  const { isOrderRecurring } = useSubscriptions();
  const {
    waiting,
    availableLocations,
    currentOrder,
    proposeTime,
    updateOrder,
    setLocation,
  } = useRequests();

  const [locations, setLocations] = useState<Location[]>([]);

  const selectLocation = useCallback(async (location : Location) => {
    const proposedTime = proposeTime({ location });
    if (
      !currentOrder
        || (currentOrder?.complete && !proposedTime.selection)
    ) return;

    const update = async (target? : 'this' | 'future') => {
      const success = currentOrder?.complete
        ? await updateOrder({
          address : null,
          location,
          timeSlot : proposedTime.selection?.timeSlot,
          iteration : proposedTime.selection?.iteration,
          division : proposedTime.selection?.division,
        }, { moveItems : true })
        : await setLocation(location, { moveItems : true })
      if (success) {
        closeModal();
        navigate('/');
      }
    }

    if (isOrderRecurring(currentOrder)) {
      openModal(
        <SubscriptionChange
          title="Update all subscriptions, or just this order?"
          prompt={`This order contains subscirption items.\n Would you like to`
            + ' apply changes to this order only'
            + (currentOrder.time
              ? ` (${formatDateTime(currentOrder.time, formats.easy)})`
              : '')
            + ', or all future orders?'}
          selectTarget={update}
        />
      );
      return;
    }

    update();
  }, [
    currentOrder,
    proposeTime,
    updateOrder,
    setLocation,
    isOrderRecurring,
    openModal,
    closeModal,
    navigate,
  ]);

  useEffect(() => {
    setLocations(Object.values(availableLocations));
  }, [availableLocations]);

  return (
    <LocationsList
      locations={locations}
      selectLocation={selectLocation}
      disabled={waiting}
    />
  );
}

export default Locations;
