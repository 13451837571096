import React from 'react';
import styled from '@emotion/styled';

import { LineItem, ProjectedOrder } from '#mrktbox/types';
import { useOptions, useSubscriptions } from '#mrktbox';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import Heading from '#materials/Heading';
import { Refresh } from '#materials/icons';

import Body from '#components/page/Body';
import ProductImage from '#components/products/ProductImage';
import CartItemCount from '#components/cart/CartItemCount';

interface Style { theme? : Theme; }

const CartSummaryItemView = styled.div`
  margin: 0 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartSummaryItemImage = styled.div<Style>`
  position: relative;
  width: 6rem;
  min-width: 6rem;
  height: 6rem;
  margin: 0 2rem 0 0;
  background-color: ${(props) => props.theme.bgColours.tertiary};
  border-radius: ${(props) => props.theme.border.radiusSmall};
`;

const CartSummaryItemContainer = styled.div`
  flex-grow: 1;
`;

const CartSummaryItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const CartSummaryItemContent = styled.div`
  flex: 1 1 100%;
`;

const CartSummaryItemName = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
`;

const CartSummaryItemMods = styled(Body)`
  margin: 0.3rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
`;

const CartSummarySubscription = styled.div<Style>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0.3rem 0 0;

  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  color: ${(props) => props.theme.buttons.colours.primary.bgColour};
`;

const CartSummaryItemPrice = styled.div<Style>`
  flex: 1 0 10rem;
  text-align: right;

  white-space: nowrap;

  span {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    font-weight: ${(props) => props.theme.boldWeight};
    color: ${(props) => props.theme.colours.primary};
  }

  s {
    opacity: 0.8;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

interface CartSummaryItemProps {
  lineItem : LineItem;
  order : ProjectedOrder | null;
  children? : any;
}

function CartSummaryItem({
  lineItem,
  order,
  children,
} : CartSummaryItemProps) {
  const { calculateLinePrice } = useOptions();
  const {
    isServiceChannelSubscribable,
    findLineItemSubscription,
  } = useSubscriptions();
  const { evaluateReccurence, formatRecurrence } = useRequests();
  const { allProducts : products, getProductImage } = useCatalogue();

  const fulfilment = order?.order?.fulfilments
  ? (Object.values(order.order.fulfilments).find((f) => (
    f.lineItemId === lineItem.id
    && f.requestedProductId === lineItem.productId
    )) ?? null) : null;
  const productId = fulfilment
    ? fulfilment.requestedProductId
    : lineItem.productId;
  const product = products ? (products[productId] ?? null) : null

  const selections = order?.selections
    ? Object.values(order.selections).filter(
      (s) => s.lineItemId === lineItem.id
    ) : [];
  const selectionsDescription = selections.map((s) => {
    if (!products) return null;
    const fulfilment = order?.order
      ? Object.values(order.order.fulfilments).find((f) => (
        f.lineItemId === s.lineItemId
          && f.requestedProductId === s.productId
      )) : undefined;
    const productId = fulfilment
      ? (fulfilment.fulfilledProductId ?? fulfilment.requestedProductId)
      : s.productId;
    return products[productId]?.name + (s.quantity > 1
      ? ` (${s.quantity})`
      : '');
  }).filter((s) => !!s).join(', ');

  const subscription = order ? findLineItemSubscription(lineItem, order) : null;
  const quantity = subscription?.quantity ?? lineItem.quantity;

  const listPrice = calculateLinePrice(lineItem, order, { adjustments : [] });
  const price = calculateLinePrice(lineItem, order);

  const specialPrice = listPrice?.amount !== price?.amount;

  const subscribable = order
    && order.customer
    && order.serviceChannel
    && (order.location || order.address)
    && order.timeSlot
    && isServiceChannelSubscribable(order.serviceChannel);
  const recurrence = subscription ? evaluateReccurence(subscription) : 0;
  const subscriptionText = subscribable
    ? formatRecurrence(recurrence)
    : '';

  const imageUrl = product ? getProductImage(product) : '';
  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};

  return (
    <CartSummaryItemView>
      <CartSummaryItemImage style={bgStyle}>
        <ProductImage product={product} variant="thumbnail">
          <CartItemCount count={quantity} />
        </ProductImage>
      </CartSummaryItemImage>
      <CartSummaryItemContainer>
        <CartSummaryItemInfo>
          <CartSummaryItemContent>
            <CartSummaryItemName as="p">
              {product?.name || 'N/A'}
            </CartSummaryItemName>
            { !!selections.length && (
              <CartSummaryItemMods as="p">
                { selectionsDescription }
              </CartSummaryItemMods>
            ) }
            { subscriptionText && (
              <CartSummarySubscription>
                { !!recurrence && (<Refresh size={9} />) }
                <p>{ subscriptionText }</p>
              </CartSummarySubscription>
            ) }
          </CartSummaryItemContent>
          <CartSummaryItemPrice>
            { (!specialPrice && price)
              ? (<span>{ formatCurrency(price) }</span>)
              : null
            }
            { (specialPrice && price && listPrice) && (
              <>
                <span><s>{ formatCurrency(listPrice) }</s></span>
                <span>{ ' ' + formatCurrency(price) }</span>
              </>
            ) }
            { children }
          </CartSummaryItemPrice>
        </CartSummaryItemInfo>
      </CartSummaryItemContainer>
    </CartSummaryItemView>
  );
}

export default CartSummaryItem;
