import {
  ServiceChannel,
  LineItem,
  Selection,
  Subscription,
  SubscriptionOption,
  isSubscription,
  isSubscriptionOption,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';
import { parseSelections } from '#mrktbox/clerk/api/mrktbox/options';
import {
  parseLineItem,
  parseLineItems,
  parseFulfilments,
  parseOrders,
} from '#mrktbox/clerk/api/mrktbox/orders';

const SUBSCRIPTIONS_PATH = 'subscriptions/';

function parseSubscription(subscription : any) {
  if (!isSubscription(subscription))
    throw new TypeError('subscription is not a Subscription Object')
  return subscription;
}

function parseSubscriptions(subscriptions : any) {
  const parsedSubscriptions : { [id : number] : Subscription } = {};
  for (const subscriptionId in subscriptions) {
    if (typeof subscriptionId !== 'string')
      throw new TypeError('Subscription id is not a string');

    const id = parseInt(subscriptionId);
    if (isNaN(id)) throw new TypeError('Subscription id is not a number');

    parsedSubscriptions[id] = parseSubscription(subscriptions[id]);
  }

  return parsedSubscriptions;
}

function parseSubscriptionOption(option : any) {
  if (!isSubscriptionOption(option))
    throw new TypeError('option is not a SubscriptionOption Object')
  return option;
}

function parseSubscriptionOptions(options : any) {
  const parsedOptions : { [id : number] : SubscriptionOption } = {};
  for (const optionId in options) {
    if (typeof optionId !== 'string')
      throw new TypeError('Subscription option id is not a string');

    const id = parseInt(optionId);
    if (isNaN(id)) throw new TypeError('Subscription option id is not a number');

    parsedOptions[id] = parseSubscriptionOption(options[id]);
  }

  return parsedOptions;
}

export async function createSubscription({
  subscription,
  selections,
  lineItem,
} : {
  subscription : Subscription,
  selections? : Selection[],
  lineItem? : LineItem,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(SUBSCRIPTIONS_PATH),
    methods.post,
    {
      subscription : {
        ...subscription,
        ...(lineItem && { lineItemId : (lineItem?.id ?? lineItem?.refId) })
      },
      selections,
      lineItem : lineItem ? {
        ...lineItem,
        price : (lineItem.price.id ? undefined : lineItem.price),
      } : undefined,
    },
    options,
  );

  try {
    return {
      subscription : parseSubscription(response.subscription),
      selections : parseSelections(response.selections),
      lineItem : parseLineItem(response.lineItem),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError('Could not deserialize subscription',
    response,
    );
  }
}

export async function retrieveSubscriptions(
  input = {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SUBSCRIPTIONS_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseSubscriptions(response.subscriptions);
  } catch {
    throw new DeserializationError(
      'Could not parse subscription list',
      response,
    );
  }
}

export async function retrieveSubscription(
  { subscriptionId } : { subscriptionId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SUBSCRIPTIONS_PATH}${subscriptionId}/`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseSubscription(response.subscription);
  } catch {
    throw new DeserializationError(
      'Could not parse subscription',
      response,
    );
  }
}

export async function bulkCreateSubscriptions({
  subscriptions,
  selections,
  lineItems,
} : {
  subscriptions : Subscription[],
  selections? : Selection[],
  lineItems? : LineItem[],
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SUBSCRIPTIONS_PATH}bulk/`),
    methods.post,
    { subscriptions, selections, lineItems },
    options,
  );

  try {
    return {
      subscriptions : parseSubscriptions(response.subscriptions),
      selections : parseSelections(response.selections),
      lineItems : parseLineItems(response.lineItems),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError(
      'Could not parse subscription list',
      response,
    );
  }
}

export async function retrieveSubscriptionOptions(
  input = {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SUBSCRIPTIONS_PATH}options/`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseSubscriptionOptions(response.subscriptionOptions);
  } catch {
    throw new DeserializationError(
      'Could not parse subscription options',
      response,
    );
  }
}

export async function addServiceChannelToSubscriptionOption({
  option,
  serviceChannel,
} : {
  option : SubscriptionOption,
  serviceChannel : ServiceChannel,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SUBSCRIPTIONS_PATH}options/${option.id}`
      + `/service-channels/${serviceChannel.id}`),
    methods.post,
    { },
    options,
  );

  try {
    return parseSubscriptionOption(response.subscriptionOption);
  } catch {
    throw new DeserializationError(
      'Could not parse subscription option',
      response,
    );
  }
}

export async function removeServiceChannelFromSubscriptionOption({
  option,
  serviceChannel,
} : {
  option : SubscriptionOption,
  serviceChannel : ServiceChannel,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SUBSCRIPTIONS_PATH}options/${option.id}`
      + `/service-channels/${serviceChannel.id}`),
    methods.delete,
    { },
    options,
  );

  try {
    return parseSubscriptionOption(response.subscriptionOption);
  } catch {
    throw new DeserializationError(
      'Could not parse subscription option',
      response,
    );
  }
}

export async function bulkCreateFulfilments({
  lineItems,
  selections,
  subscriptions,
  targetIteration,
} : {
  lineItems? : LineItem[],
  selections? : Selection[],
  subscriptions? : Subscription[],
  targetIteration? : number,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SUBSCRIPTIONS_PATH}fulfilments/bulk/`),
    methods.post,
    {
      lineItemIds : lineItems?.map((lineItem) => lineItem.id),
      selectionIds : selections?.map((selection) => selection.id),
      subscriptionIds : subscriptions?.map((subscription) => subscription.id),
      targetIteration,
    },
    options,
  );

  try {
    return {
      fulfilments : parseFulfilments(response.fulfilments),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse order list',
      response,
    );
  }
}
