import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { LineItem } from '#mrktbox/types';
import { formats, formatDateTime } from '#mrktbox/utils';

import { Theme } from '#types';

import useModal from '#hooks/useModal';
import useSidebar from '#hooks/useSidebar';
import useRequests from '#hooks/useRequests';
import useLogin from '#hooks/useLogin';

import ButtonStyled from '#materials/ButtonStyled';
import IconTextButton from '#materials/IconTextButton';
import TimeButton from '#materials/TimeButton';
import FulfilmentButton from '#materials/FulfilmentButton';
import ServiceButton from '#materials/ServiceButton';

import SidebarHeader from '#components/sidebar/SidebarHeader';
import SidebarFooter from '#components/sidebar/SidebarFooter';
import Login from '#components/auth/Login';
import OrderTime from '#components/orders/OrderTime';
import CartItem from '#components/cart/CartItem';
import Totals from '#components/cart/Totals';
import Subtotal from '#components/cart/Subtotal';
import Adjustment from '#components/cart/Adjustment';
import Tax from '#components/cart/Tax';

import { formatAddress } from '#mrktbox/utils';

interface Style { theme? : Theme; }
interface OverlayStyle extends Style { show? : boolean; }

const SidebarOverlay = styled.div<OverlayStyle>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  width: ${(props) => props.theme.breakpoints.mobile};
  height: 100%;
  z-index: 1000;
  opacity: 0.2;
  background-color: ${(props) => props.theme.colours.dark};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const SidebarCart = styled.div<Style>`
  width: ${(props) => props.theme.breakpoints.mobile};
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  padding: 0 0 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SidebarCartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const SidebarCartItems = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0 2rem;
`;

function Cart() {
  const { openModal } = useModal();
  const { closeSidebar } = useSidebar();
  const { signing, user } = useLogin();
  const {
    waiting,
    serviceChannel,
    address,
    location,
    time,
    cutoff,
    currentOrder,
    hasCardOnFile,
    isOrderStocked,
    isOrderAvailable,
    getLineItems,
    getSubtotal,
    getAdjustments,
    getTaxes,
    getTotal,
    newOrder,
    generateOrderUrl,
  } = useRequests();

  const [lineItems, setLineItems] = useState(getLineItems());
  const [subtotal, setSubtotal] = useState(getSubtotal());
  const [adjustments, setAdjustments] = useState(getAdjustments());
  const [taxes, setTaxes] = useState(getTaxes());
  const [total, setTotal] = useState(getTotal());
  const [validTime, setValidTime] = useState(false);
  const [busy, setBusy] = useState<{[id : number] : Boolean}>({});

  const setItemBusy = useCallback((item : LineItem, b : Boolean) => {
    const itemId = item.id;
    if (!itemId) return;
    if (busy[itemId] === b) return;
    setBusy((prev) => ({ ...prev, [itemId]: b }));
  }, [busy]);

  const handleSelectTime = useCallback(() => {
    openModal((<OrderTime />));
  }, [openModal]);

  const handleContinue = useCallback(() => {
    if (!serviceChannel || (!address && !location)) return;
    if (time === null || !validTime) handleSelectTime();
    else if (!user) openModal((<Login />));
  }, [
    validTime,
    handleSelectTime,
    serviceChannel,
    address,
    location,
    time,
    user,
    openModal,
  ]);

  const handleNewOrder = useCallback(() => {
    newOrder();
    closeSidebar();
  }, [newOrder, closeSidebar]);

  useEffect(() => {
    setLineItems(getLineItems());
    setSubtotal(getSubtotal());
    setAdjustments(getAdjustments());
    setTaxes(getTaxes());
    setTotal(getTotal());
  }, [getLineItems, getSubtotal, getTaxes, getAdjustments, getTotal]);

  useEffect(() => {
    const cutoffInterval = setInterval(() => {
      setValidTime(!cutoff || new Date().getTime() < cutoff.getTime());
    }, 100);
    return () => clearInterval(cutoffInterval);
  }, [time, cutoff]);

  const title = currentOrder?.address
    ? `Delivery Order`
    : (currentOrder?.location
      ? `Pickup Order`
      : 'Your Current Order');
  const preface = currentOrder?.order?.id
    ? `Order #${currentOrder.order.id}`
    : '';

  const available = isOrderAvailable();
  const stocked = isOrderStocked();
  const error = !available || !stocked;
  const started = serviceChannel || address || location || time;

  const needsChannel = !serviceChannel;
  const needsAddress = (!needsChannel) && (!address && !location);
  const needsTime = (!needsChannel && !needsAddress)
    && (time === null || !validTime);
  const needsUser = (!needsChannel && !needsAddress && !needsTime) && !user;
  const needsCard = !serviceChannel?.requireCheckout && !hasCardOnFile();
  const needsItems = !lineItems || !lineItems.length;
  const complete = !needsChannel && !needsAddress && !needsTime && !needsUser;
  const paid = currentOrder?.order
      ? !!Object.values(currentOrder.order.payments).length
      : false;

  const errorLable = (!available || !stocked)
    ? 'Unavailable Items'
    : '';

  const continueLabel = needsChannel
    ? 'Select Order Type'
    : needsAddress
      ? 'Select Delivery or Pickup'
      : needsTime
        ? 'Select a Time'
        : needsUser
          ? 'Sign In'
          : needsCard
            ? 'Add Payment Method'
            : !serviceChannel.requireCheckout
              ? 'Recieved'
              : paid ? 'View Order' : 'Checkout';

  const continueHref = needsChannel
    ? '/order-types/'
    : needsAddress
      ? '/locations/'
      : (needsTime || needsUser)
        ? '#'
        : needsCard
          ? '/credit-cards/'
          : (serviceChannel.requireCheckout && currentOrder)
            ? generateOrderUrl(currentOrder, true)
            : '#';

  const isBusy = Object.values(busy).some((b) => b);

  return (
    <>
      <SidebarOverlay show={waiting} />
      <SidebarHeader title={waiting ? 'Updating...' : title} preface={preface}>
        { !!serviceChannel && (
          <ServiceButton
            serviceChannel={serviceChannel}
            href='/order-types/'
            disabled={waiting}
            compact
          />
        ) }
        { !!(address || location) && (
          <FulfilmentButton
            name={(address ? formatAddress(address) : location?.name) ?? ''}
            href='/locations/'
            disabled={waiting}
            compact
          />
        ) }
        { (time !== null) && (
          <TimeButton
            datetime={time}
            onClick={handleSelectTime}
            disabled={waiting}
            compact
          />
        ) }
        { !!cutoff && (
          <IconTextButton
            colour="header"
            text={validTime
              ? `Order Before ${formatDateTime(cutoff, formats.easy)}`
              : 'The cutoff time for this order has passed'}
          />
        ) }
      </SidebarHeader>
      <SidebarCart id={'cart-view'}>
        <SidebarCartContainer>
          <SidebarCartItems>
            { lineItems && Object.values(lineItems).map((item, i) => (
              !!item && (
                <CartItem
                  key={`${item?.id ?? item?.productId ?? 'item'}-${i}`}
                  item={item}
                  order={currentOrder}
                  setBusy={(b) => setItemBusy(item, b)}
                />
              )
            )) }
          </SidebarCartItems>
        </SidebarCartContainer>
      </SidebarCart>
      { !!total && (
        <Totals total={total?.total}>
          { !!subtotal && (
            <Subtotal label="Subtotal" subtotal={subtotal.total} bold />
          ) }
          { adjustments && adjustments.map((adjustment) => (
            <Adjustment key={adjustment.adjustmentId} total={adjustment} />
          )) }
          { taxes && taxes.map((tax) => (
            <Tax key={tax.taxId} total={tax} />
          )) }
        </Totals>
      ) }
      <SidebarFooter>
        { (!complete || !needsItems) && (
          <ButtonStyled
            size="big"
            href={continueHref}
            onClick={handleContinue}
            disabled={error
              || waiting
              || isBusy
              || signing
              || (complete && !needsCard && !serviceChannel.requireCheckout)
            }
            colour="primary"
            fullwidth
          >
            { error
              ? errorLable
              : (isBusy ? 'Confirm Subscription' : continueLabel)
            }
          </ButtonStyled>
        ) }
        { (started && !serviceChannel?.requireCheckout) && (
          <ButtonStyled
            size="big"
            colour="secondary"
            onClick={handleNewOrder}
            fullwidth
          >
            New Order
          </ButtonStyled>
        ) }
      </SidebarFooter>
    </>
  );
}

export default Cart;
