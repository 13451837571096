import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { ProjectedOrder } from '#mrktbox/types';
import { useSubscriptions } from '#mrktbox';

import ButtonStyled from '#materials/ButtonStyled';

import PageContent from '#components/page/PageContent';
import PageTitle from '#components/page/PageTitle';
import OrderList from '#components/orders/OrderList';

const SOON = 14 * 24 * 60 * 60 * 1000;
const MAX_HISTORY = 14 * 24 * 60 * 60 * 1000;

const soonThreshold = new Date(Date.now() + SOON);
const historyThreshold = (i : number) => new Date(Date.now() - i * MAX_HISTORY);

function filterOpenOrders(orders : ProjectedOrder[]) {
  return orders.filter((order) => (
    (order.complete && (!order.serviceChannel?.requireCheckout || order.paid))
      && order.status !== 'cancelled'
      && order.status !== 'fulfilled'
  )).sort((a, b) => (a.time?.getTime() ?? 0) - (b.time?.getTime() ?? 0));
}

function filterClosedOrders(orders : ProjectedOrder[]) {
  return orders.filter((order) => (
    (order.complete && (!order.serviceChannel?.requireCheckout || order.paid))
      && (order.status === 'cancelled' || order.status === 'fulfilled')
  )).sort((a, b) => {
    return (b.time?.getTime() ?? 0) - (a.time?.getTime() ?? 0);
  }).slice(0, MAX_HISTORY);
}

function filterDrafts(orders : ProjectedOrder[]) {
  return orders.filter((order) => (
    (!order.complete || (order.serviceChannel?.requireCheckout && !order.paid))
      && (!order.time || order.time > new Date())
  )).sort((a, b) => (a.time?.getTime() ?? 0) - (b.time?.getTime() ?? 0));
}

const ButtonView = styled.div`
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin: auto;
`;

function Orders() {
  const { projectedOrders } = useSubscriptions();

  const [showAllUpcoming, setShowAllUpcoming] = useState(false);
  const [showHistory, setShowHistory] = useState(1);
  const [past, setPast] = useState(filterClosedOrders(projectedOrders));
  const [upcoming, setUpcoming] = useState(filterOpenOrders(projectedOrders));
  const [drafts, setDrafts] = useState(filterDrafts(projectedOrders));

  const soon = upcoming.filter((i) => !i.time || i.time < soonThreshold);
  const recent = past.filter(
    (i) => i.time && i.time > historyThreshold(showHistory)
  );

  useEffect(() => {
    setPast(filterClosedOrders(projectedOrders));
    setUpcoming(filterOpenOrders(projectedOrders));
    setDrafts(filterDrafts(projectedOrders));
  }, [projectedOrders]);

  return (
    <>
      { !!drafts.length && (
        <>
          <PageTitle
            title="Draft Orders"
            subtitle="Finish and submit your orders"
          />
          <OrderList orders={drafts} />
        </>
      ) }
      <PageTitle
        title="Upcoming Orders"
        subtitle="Manage your current orders"
      />
      { !!upcoming.length
        ? (
          <>
            <OrderList
              orders={upcoming}
              pageCount={showAllUpcoming ? undefined : soon.length}
            />
            { soon.length < upcoming.length && (
              <ButtonView>
                <ButtonStyled
                  onClick={() => setShowAllUpcoming(!showAllUpcoming)}
                >
                  { showAllUpcoming ? 'Hide' : 'Show All' }
                </ButtonStyled>
              </ButtonView>
            ) }
          </>
        ) : (
          <PageContent>
            <p>Looks like you don't have any upcoming orders.</p>
          </PageContent>
        )
      }
      <PageTitle
        title='Order History'
        subtitle={'Reorder past orders, ' +
          'or add order ratings & comments.'}
        style={{ marginTop : '8rem' }}
      />
      { past.length
        ? (
          <>
            <OrderList orders={recent} />
            { !((recent.length === past.length) && (showHistory < 2)) && (
              <ButtonView>
                <ButtonStyled
                  onClick={() => setShowHistory(showHistory + 1)}
                  disabled={recent.length === past.length}
                >
                  Show More
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => setShowHistory(showHistory - 1)}
                  disabled={showHistory < 2}
                >
                  Show Less
                </ButtonStyled>
              </ButtonView>
            ) }
          </>
        ) : (
          <PageContent>
            <p>Looks like you don't have any past orders.</p>
          </PageContent>
        )
      }
    </>
  );
}

export default Orders;
