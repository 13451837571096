(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-router-dom"), require("@turf/turf"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-router-dom", "@turf/turf"], factory);
	else if(typeof exports === 'object')
		exports["MrktboxClerk"] = factory(require("react"), require("react-router-dom"), require("@turf/turf"));
	else
		root["MrktboxClerk"] = factory(root["React"], root["react-router-dom"], root["@turf/turf"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__787__, __WEBPACK_EXTERNAL_MODULE__128__, __WEBPACK_EXTERNAL_MODULE__744__) => {
return 