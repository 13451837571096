import { useContext } from 'react';

import NotificationContext from '#mrktbox/clerk/context/NotificationContext';

function useNotifications() {
  const {
    open,
    close,
    closed,
    notification,
    createNotification,
  } = useContext(NotificationContext);

  return {
    open,
    close,
    closed,
    notification,
    createNotification,
  };
}

export default useNotifications;
