import { Tag, isTag } from './tags';
import { DraftOrder } from './orders';

export interface Assembly {
  id? : number;
  name : string;
  complimentary : boolean;
  cumulative : boolean;
  productIds : number[];
  collectionIds : number[];
}

export function isAssembly(assembly : any) : assembly is Assembly {
  return (
    typeof assembly === 'object' &&
    (assembly.id === undefined || typeof assembly.id === 'number') &&
    typeof assembly.name === 'string' &&
    typeof assembly.complimentary === 'boolean' &&
    typeof assembly.cumulative === 'boolean' &&
    Array.isArray(assembly.productIds) &&
    assembly.productIds.every((id : any) => typeof id === 'number') &&
    Array.isArray(assembly.collectionIds) &&
    assembly.collectionIds.every((id : any) => typeof id === 'number')
  )
}

export interface Collection extends Tag {
  id? : number;
  min : number;
  max : number;
  starting : Date | null;
  ending : Date | null;
  tagIds : number[];
}

export function isCollection(collection : any) : collection is Collection {
  return (
    typeof collection === 'object' &&
    (collection.id === undefined || typeof collection.id === 'number') &&
    typeof collection.min === 'number' &&
    typeof collection.max === 'number' &&
    (collection.starting === null || collection.starting instanceof Date) &&
    (collection.ending === null || collection.ending instanceof Date) &&
    Array.isArray(collection.tagIds) &&
    collection.tagIds.every((id : any) => typeof id === 'number') &&
    isTag(collection)
  )
}

export interface Selection {
  id? : number;
  lineItemId : number;
  assemblyId : number;
  productId : number;
  quantity : number;
  fulfilmentIds : number[];
}

export function isSelection(selection : any) : selection is Selection {
  return (
    typeof selection === 'object' &&
    (selection.id === undefined || typeof selection.id === 'number') &&
    typeof selection.lineItemId === 'number' &&
    typeof selection.assemblyId === 'number' &&
    typeof selection.productId === 'number' &&
    typeof selection.quantity === 'number' &&
    Array.isArray(selection.fulfilmentIds) &&
    selection.fulfilmentIds.every((id : any) => typeof id === 'number')
  )
}

export interface DraftCustomOrder extends DraftOrder {
  selections : { [id : number] : Selection };
}
