import React, { createContext, useCallback, useContext } from 'react';

import { Customer, CreditCard } from '#mrktbox/clerk/types';
import CustomersContext from '#mrktbox/clerk/context/CustomersContext';

import useData, {
  DataIndex,
  useLoad,
  useRelate,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useDelete,
} from '#mrktbox/clerk/hooks/useData';
import useAccountsAPI from '#mrktbox/clerk/hooks/api/useAccountsAPI';

const MAX_AGE = 1000 * 60 * 60;

export type CreditCardsIndex = DataIndex<CreditCard>;

const CardsContext = createContext({
  creditCards : null as CreditCardsIndex | null,
  loaded : false as boolean,
  load : () => {},
  createCreditCard : async (
    cc : CreditCard,
    customer : Customer,
    validation? : { token : string, postal : string }
  ) => null as CreditCard | null,
  refreshCreditCards : async () => null as CreditCardsIndex | null,
  refreshCreditCard : async (id : number) => null as CreditCard | null,
  retrieveCreditCards : async () => null as CreditCardsIndex | null,
  retrieveCreditCard : async (id : number) => null as CreditCard | null,
  deleteCreditCard : async (cc : CreditCard) => null as CreditCard | null,
});

interface CardsProviderProps {
  children : React.ReactNode;
}

export function CardsProvider({ children } : CardsProviderProps) {
  const { refreshCustomer } = useContext(CustomersContext);
  const {
    retrieveCreditCards,
    retrieveCreditCard,
    createCreditCard,
    deleteCreditCard,
  } = useAccountsAPI();

  const refreshCardCustomer = useCallback(
    ( cc : CreditCard ) => { refreshCustomer(cc.customerId); },
    [refreshCustomer],
  );

  const {
    data: creditCards,
    dispatch: dispatchCreditCards,
    lastUpdated,
  } = useData<CreditCard>({ storageKey : 'creditCards' });

  const newCreditCard = useRelate({
    dispatch : dispatchCreditCards,
    relate : createCreditCard,
    callback : refreshCardCustomer
  });
  const refreshCreditCards = useRefreshIndex({
    dispatch : dispatchCreditCards,
    retrieve : retrieveCreditCards,
  });
  const refreshCreditCard = useRefresh({
    dispatch : dispatchCreditCards,
    retrieve : retrieveCreditCard,
  });
  const getCreditCards = useRetrieveIndex({
    data : creditCards,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshCreditCards,
  });
  const getCreditCard = useRetrieve({
    data : creditCards,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshCreditCard,
  });
  const removeCreditCard = useDelete({
    dispatch : dispatchCreditCards,
    delete : deleteCreditCard,
  });

  const { loaded, load } = useLoad({
    data : creditCards,
    loader : refreshCreditCards,
  });

  const context = {
    creditCards,
    loaded,
    load,
    createCreditCard: newCreditCard,
    refreshCreditCards,
    refreshCreditCard,
    retrieveCreditCards : getCreditCards,
    retrieveCreditCard : getCreditCard,
    deleteCreditCard : removeCreditCard,
  };

  return (
    <CardsContext.Provider value={context}>
      { children }
    </CardsContext.Provider>
  );
}

export default CardsContext;
