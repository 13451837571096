import React from 'react';

import { Address } from '#mrktbox/types';

interface OrderAddressProps {
  address : Address;
  isCard : boolean;
  children : any;
}

function OrderAddress({ address, isCard, children } : OrderAddressProps) {
  return (
    <>
      <p className={isCard ? 'title' : ''}>{address.street}</p>
      <p>
        {address.city}, {address.postal}
      </p>
      {children}
    </>
  );
}

export default OrderAddress;
