import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import { useParams } from '#mrktbox';
import { Category } from '#mrktbox/types';
import { listRecords } from '#mrktbox/utils';

import useConfig from '#hooks/useConfig';
import useCatalogue from '#hooks/useCatalogue';

import ScreenreaderTitle from '#materials/ScreenreaderTitle';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import CategoryIndex from '#components/categories/CategoryIndex';

const CategoryProductsView = styled.div``;

const CategoryProductsAnnouncements = styled.div``;

function CategoryProducts(){
  const urlParams = useParams<{ categoryId? : string; }>();
  const parsedId = parseInt(urlParams.categoryId || '0');
  const categoryId = isNaN(parsedId) ? undefined : parsedId;
  const categorySlug = urlParams.categoryId;

  const { brand } = useConfig();

  const heroRef = useRef<HTMLDivElement>(null);

  const { allCategories, getCategorySlug } = useCatalogue();
  const [ category, setCategory ] = useState<Category | null>(null);

  useEffect(() => {
    if (!categoryId && !categorySlug) return;
    const cat = listRecords(allCategories).find((c) => (
      (categoryId && c.id === categoryId)
        || (categorySlug && getCategorySlug(c) === categorySlug)
    ));
    if (cat) setCategory(cat);
  }, [categoryId, categorySlug, setCategory, allCategories, getCategorySlug]);

  return (
    <>
      <Helmet>
        <title>
          { (category?.name ? `${category.name} | ` : '') + brand.title }
        </title>
      </Helmet>
      <Content>
        <Main>
          <ScreenreaderTitle>Category Products</ScreenreaderTitle>
          <CategoryProductsView>
            <CategoryProductsAnnouncements ref={heroRef}>
            </CategoryProductsAnnouncements>
            { category && (
              <CategoryIndex
                category={category}
              />
            ) }
          </CategoryProductsView>
        </Main>
      </Content>
    </>
  );
}

export default CategoryProducts;
