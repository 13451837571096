import React from 'react';

import { AdjustmentProvider } from './AdjustmentContext';
import { AreaProvider } from './AreaContext';
import { AuthProvider } from './AuthContext';
import { CardsProvider } from './CardsContext';
import { CategoryProvider } from './CategoryContext';
import { ConditionProvider } from './ConditionContext';
import { CustomersProvider } from './CustomersContext';
import { IntegrationProvider } from './IntegrationContext';
import { LocationProvider } from './LocationContext';
import { OptionsProvider } from './OptionsContext';
import { OrderProvider } from './OrderContext';
import { ProductsProvider } from './ProductsContext';
import { RevenueCentreProvider } from './RevenueCentreContext';
import { RouteProvider } from './RouteContext';
import { ScheduleProvider } from './ScheduleContext';
import { ServiceChannelProvider } from './ServiceChannelContext';
import { ServiceProvider } from './ServiceContext';
import { SubscriptionsProvider } from './SubscriptionsContext';
import { SupplierProvider } from './SupplierContext';
import { TagProvider } from './TagContext';

function MrktboxProviders({ children } : { children : React.ReactNode }) {
  return (
    <AuthProvider>
    <IntegrationProvider>
    <AreaProvider>
    <LocationProvider>
    <ServiceChannelProvider>
    <AdjustmentProvider>
    <ConditionProvider>
    <ServiceProvider>
    <ScheduleProvider>
    <RouteProvider>
    <RevenueCentreProvider>
    <ProductsProvider>
    <TagProvider>
    <CategoryProvider>
    <SupplierProvider>
    <CustomersProvider>
    <CardsProvider>
    <OrderProvider>
    <OptionsProvider>
    <SubscriptionsProvider>
      { children }
    </SubscriptionsProvider>
    </OptionsProvider>
    </OrderProvider>
    </CardsProvider>
    </CustomersProvider>
    </SupplierProvider>
    </CategoryProvider>
    </TagProvider>
    </ProductsProvider>
    </RevenueCentreProvider>
    </RouteProvider>
    </ScheduleProvider>
    </ServiceProvider>
    </ConditionProvider>
    </AdjustmentProvider>
    </ServiceChannelProvider>
    </LocationProvider>
    </AreaProvider>
    </IntegrationProvider>
    </AuthProvider>
  );
}

export default MrktboxProviders;
