import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

interface ButtonLinkViewStyle {
  theme : Theme;
  colour : 'primary' | 'light';
}

const LinkView = styled.a<ButtonLinkViewStyle>`
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: inline;
  white-space: nowrap;
  font-size: inherit;
  letter-spacing: inherit;
  text-decoration: ${(props) => props.theme.links.textDecoration};
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.links[props.colour].colour};

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.links[props.colour].hover};
  }

  &:disabled {
    color: ${(props) => props.theme.links[props.colour].colour};
    opacity: 0.5;
  }
`

const ButtonLinkView = styled.button<ButtonLinkViewStyle>`
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: inline;
  white-space: nowrap;
  font-size: inherit;
  letter-spacing: inherit;
  text-decoration: ${(props) => props.theme.links.textDecoration};
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.links[props.colour].colour};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.links[props.colour].hover};
  }

  &:disabled {
    color: ${(props) => props.theme.links[props.colour].colour};
    opacity: 0.5;
  }
`

interface ButtonLinkProps {
  label? : string;
  children : React.ReactNode;
  disabled? : boolean;
  href? : string;
  newTab? : boolean;
  onClick? : () => void;
  colour? : 'primary' | 'light';
}

const ButtonLink = ({
  label,
  children,
  disabled,
  href,
  newTab,
  colour = 'primary',
  onClick,
} : ButtonLinkProps) => {
  const { theme } = useConfig();

  const handleClick = useCallback(
    (event : React.MouseEvent<
      HTMLButtonElement | HTMLAnchorElement,
      MouseEvent
    >) => {
      if (!href) event.preventDefault();
      event.stopPropagation();
      if (onClick) onClick();
    },
    [href, onClick],
  )

  if (href) return (
    <LinkView
      href={href}
      onClick={handleClick}
      theme={theme}
      colour={colour}
      {...(newTab && {
        target : '_blank' ,
        rel : 'noopener noreferrer',
      })}
    >
      {children}
    </LinkView>
  );

  return (
    <ButtonLinkView
      type="button"
      aria-label={label}
      onClick={handleClick}
      disabled={disabled}
      theme={theme}
      colour={colour}
    >
      {children}
    </ButtonLinkView>
  );
}

export default ButtonLink
