import React, { useState, useCallback } from 'react';

import { useNavigation } from '@mrktbox/clerk';

import useModal from '#hooks/useModal';
import useLogin from '#hooks/useLogin';

import Text from '#materials/Text';
import ButtonLink from '#materials/ButtonLink';

import ModalView from '#components/modal/ModalView';
import ModalClose from '#components/modal/ModalClose';
import ModalContent from '#components/modal/ModalContent';
import LoginForm from '#components/auth/LoginForm';
import SendReset from '#components/auth/SendReset';

interface LoginProps {
  resetEmail?: string;
  activating?: boolean;
}

function Login({ resetEmail, activating } : LoginProps) {
  const { navigate } = useNavigation();
  const { closeModal } = useModal();
  const { login } = useLogin();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [reset, setReset] = useState(!!resetEmail);

  const submit = useCallback(async () => {
    const { user, error } = await login(email, password);

    if (error) {
      setError('Sorry, something went wrong. Please try again.');
    } else if (user) {
      setError('');
      navigate('/');
      closeModal();
    } else {
      setError('Email or password is incorrect.');
    }
  }, [email, password, login, closeModal, navigate]);

  const handleSignUp = useCallback(() => {
    closeModal();
    navigate('/signup/');
  }, [closeModal, navigate]);

  return (
    <ModalView>
      <ModalClose/>
      <ModalContent
        title={activating ? 'Activate your account' : 'Login to your account'}
      >
        { !reset
          ? (
            <>
              <Text style={{ margin: '2rem 0' }}>
                Don't have an account?{' '}
                <ButtonLink onClick={handleSignUp}>
                  Click here to sign up.
                </ButtonLink>
              </Text>
              <LoginForm
                email={email}
                password={password}
                setEmail={setEmail}
                setPassword={setPassword}
                onSubmit={submit}
                onReset={() => setReset(true)}
                error={error}
              />
            </>
          ) : (
            <SendReset
              init={resetEmail}
              activating={activating}
              onClose={() => setReset(false)}
            />
          )
        }
      </ModalContent>
    </ModalView>
  );
}

export default Login;
