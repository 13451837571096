import { useContext, useCallback } from 'react';

import { Product, Tag, isProduct, isTag } from '#mrktbox/clerk/types';

import TagContext from '#mrktbox/clerk/context/TagContext';

function useTags() {
  const {
    tags,
    loaded,
    load,
    refreshTags,
    refreshTag,
    createTag,
    retrieveTags,
    retrieveTag,
    updateTag,
    deleteTag,
    addProductToTag,
    removeProductFromTag,
    addServiceChannelToTag,
    removeServiceChannelFromTag,
  } = useContext(TagContext);

  const addProduct = useCallback(
    async (tag: Product | Tag, product: Product | Tag) => {
      if (isTag(tag) && isProduct(product)) {
        return await addProductToTag(tag, product);
      } else if (isProduct(tag) && isTag(product)) {
        // DEPR: (product, tag) => boolean
        return await addProductToTag(product, tag);
      } else {
        throw new Error('Invalid argument combination');
      }
    },
    [addProductToTag],
  );

  const removeProduct = useCallback(
    async (tag: Product | Tag, product: Product | Tag) => {
      if (isTag(tag) && isProduct(product)) {
        return await removeProductFromTag(tag, product);
      } else if (isProduct(tag) && isTag(product)) {
        // DEPR: (product, tag) => boolean
        return await removeProductFromTag(product, tag);
      } else {
        throw new Error('Invalid argument combination');
      }
    },
    [removeProductFromTag],
  );

  return {
    tags,
    loaded,
    load,
    refreshTags,
    refreshTag,
    createTag,
    retrieveTags,
    retrieveTag,
    updateTag,
    deleteTag,
    addProductToTag : addProduct,
    removeProductFromTag : removeProduct,
    addServiceChannelToTag,
    removeServiceChannelFromTag,
  };
}

export default useTags;
