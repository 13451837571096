
export interface MetaData {
  [key : string] : string | number | boolean | null;
}

export interface LogEntry {
  id : number;
  eventCode : string;
  message : string;
  timestamp : Date;
  metaData : {
    [key : number] : MetaData;
  }
}

export interface EventReport {
  counts : { [key : string] : number };
}

export function isMetaData(data : any) : data is MetaData {
  return (
    typeof data === 'object' &&
    data !== null &&
    Object.keys(data).every(key => typeof key === 'string') &&
    Object.values(data).every(value => (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      value === null
    ))
  );
}

export function isLogEntry(entry : any) : entry is LogEntry {
  return (
    typeof entry === 'object' &&
    entry !== null &&
    typeof entry.id === 'number' &&
    typeof entry.eventCode === 'string' &&
    typeof entry.message === 'string' &&
    entry.timestamp instanceof Date &&
    isMetaData(entry.metaData)
  );
}

export function isFrequencyReport(report : any) : report is EventReport {
  return (
    typeof report === 'object' &&
    report !== null &&
    typeof report.counts === 'object' &&
    report.counts !== null &&
    Object.values(report.counts).every(value => typeof value === 'number')
  );
}
