import React, { useCallback, useEffect, useState } from 'react';

import { Product, ProjectedOrder } from '#mrktbox/types';
import {
  useNavigation,
  useScheduling,
  useSubscriptions,
} from '#mrktbox';
import {
  generateRange,
  formatCurrency,
  formatDateTime,
  formats,
} from '#mrktbox/utils';

import useModal from '#hooks/useModal';
import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import Card from '#materials/Card';
import ButtonStyled from '#materials/ButtonStyled';

import OrderImages from '#components/orders/OrderImages';
import OrderTag from '#components/orders/OrderTag';
import SkipOrder from '#components/orders/SkipOrder';

interface OrderCardProps {
  order : ProjectedOrder;
}

function OrderCard({ order } : OrderCardProps) {
  const { navigate } = useNavigation();
  const { openModal, closeModal } = useModal();
  const {
    getIterationDivision,
    calculateTime,
    calculateDuration,
  } = useScheduling();
  const { isOrderRecurring, updateOrder } = useSubscriptions();
  const {
    refresh,
    generateOrderUrl,
    isOrderOpen,
    calculateCutoff,
    getLineItems,
    editOrder,
  } = useRequests();
  const { allProducts } = useCatalogue();

  const getProducts = useCallback(() => {
    if (!order || !allProducts) return [];
    return getLineItems(order).map((item) => allProducts[item.productId])
      .filter((p) => !!p) as Product[];
  }, [order, allProducts, getLineItems]);

  const [products, setProducts] = useState<Product[]>(getProducts());

  const handleEdit = useCallback(() => {
    editOrder(order);
    navigate('/');
  }, [order, navigate, editOrder]);

  const handleSkip = useCallback(() => {
    const iterations = generateRange(4, order.timeSlotIteration + 1);
    const timeSlot = order.timeSlot;
    if (!timeSlot) return;

    const newTimes = iterations.map((i) => {
      const division = order.order?.timeSlotDivision ?? 0;
      const start = calculateTime(timeSlot, i, division);
      const end = !timeSlot.division
        ? new Date(start.getTime() + calculateDuration(timeSlot, i))
        : null;
      const cutoff = calculateCutoff({
        ...order,
        timeSlotIteration : i,
        time : start,
      });
      return { start, end, cutoff };
    }).filter((t) => !!t) as {
      start : Date,
      end : Date | null,
      cutoff : Date,
    }[];

    openModal((
      <SkipOrder
        orderName={order.order
          ? `order #${order.order?.id}`
          : 'upcoming order'}
        newTimes={newTimes}
        onSelect={async (time) => {
          const selection = getIterationDivision(timeSlot, time);
          if (!selection) return false;

          const success = (await updateOrder({
            ...order,
            timeSlotIteration : selection.iteration,
            timeSlotDivision : selection.division,
          }, {
            target : 'future',
            targetTime : {
              timeSlot,
              iteration : order.timeSlotIteration,
              division : order.timeSlotDivision,
            }
          })).success;

          if (success) {
            await refresh();
            closeModal();
          }
        }}
        onCancel={closeModal}
      />
    ));
  }, [
    order,
    refresh,
    calculateCutoff,
    getIterationDivision,
    calculateTime,
    calculateDuration,
    updateOrder,
    openModal,
    closeModal,
  ]);

  useEffect(() => { setProducts(getProducts()); }, [getProducts]);

  const total = order.totals.find((t) => t.key === 'total')?.total ?? null;

  const preface = order.order
    ? `Order #${order.order.id}`
    : (order.status === 'cancelled'
      ? 'Cancelled Order'
      : (order.status === 'fulfilled'
        ? 'Past Order'
        : (order.complete ? 'Upcoming Order' : 'Draft Order')));

  const title = order.address
    ? 'Delivery Order'
    : (order.location ? `Pickup from ${order.location.name}` : 'New Order');

  const orderLink = order.order
    ? `/orders/${order.order.id}`
    : generateOrderUrl(order);

  const open = isOrderOpen(order);
  const isSubscription = isOrderRecurring(order);
  const requireCheckout = order.serviceChannel?.requireCheckout;
  const draft = !order.complete || (requireCheckout && !order.paid);
  const status = draft ? 'draft' : order.status;

  return (
    <Card
      tag={(
        <OrderTag
          status={status}
          subscription={isSubscription}
        />
      )}
      preface={preface}
      title={title}
      content={
        <>
          <p>
            { !!order.time && formatDateTime(order.time, formats.short) }
            { (total && order.time) && <>&nbsp;|&nbsp;</> }
            { !!total && formatCurrency(total) }
          </p>
          <p>
            { order.address
              ? order.address.description
              : order.location?.address?.description
            }
          </p>
          <OrderImages products={products} order={order}/>
        </>
      }
      footer={
        <>
          { (!draft && orderLink) && (
            <ButtonStyled
              href={orderLink}
              size="small"
              colour="secondary"
            >
              Details
            </ButtonStyled>
          ) }
          { open && (
            <ButtonStyled
              size="small"
              onClick={handleEdit}
            >
              Edit
            </ButtonStyled>
          ) }
          { (open && isSubscription) && (
            <ButtonStyled
              size="small"
              onClick={handleSkip}
            >
              Skip
            </ButtonStyled>
          ) }
        </>
      }
    />
  );
}

export default OrderCard;
