import {
  LineItem,
  Assembly,
  Collection,
  Selection,
  Product,
  Tag,
  isAssembly,
  isCollection,
  isSelection,
} from '#mrktbox/clerk/types';

import { APIError, DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

import { parseDateTime } from '#mrktbox/clerk/utils';

import {
  getGuestCode,
  parseLineItems,
  parseOrders,
} from '#mrktbox/clerk/api/mrktbox/orders';

const OPTIONS_PATH = 'options/';
const ASSEMBLIES_PATH  = `${OPTIONS_PATH}assemblies/`;
const COLLECTIONS_PATH = `${OPTIONS_PATH}collections/`;
const SELECTIONS_PATH = `${OPTIONS_PATH}selections/`;

function parseAssembly(assembly : any) {
  if (!isAssembly(assembly)) throw new TypeError('Assembly is not an assembly');
  return assembly;
}

function parseAssemblies(assemblies : any) {
  const parsedAssemblies : { [id : number] : Assembly } = {};
  for (const assemblyId in assemblies) {
    if (typeof assemblyId !== 'string')
      throw new TypeError('Assembly id is not a string');

    const id = parseInt(assemblyId);
    if (isNaN(id)) throw new TypeError('Assembly id is not a number');

    parsedAssemblies[id] = parseAssembly(assemblies[id]);
  }

  return parsedAssemblies;
}

function parseCollection(collection : any) {
  const parsedCollection = {
    ...collection,
    starting : parseDateTime(collection.starting),
    ending : collection.ending ? parseDateTime(collection.ending) : null,
  };

  if (!isCollection(parsedCollection))
    throw new TypeError('Collection is not a collection');
  return parsedCollection;
}

function parseCollections(collections : any) {
  const parsedCollections : { [id : number] : Collection } = {};
  for (const collectionId in collections) {
    if (typeof collectionId !== 'string')
      throw new TypeError('Collection id is not a string');

    const id = parseInt(collectionId);
    if (isNaN(id)) throw new TypeError('Collection id is not a number');

    parsedCollections[id] = parseCollection(collections[id]);
  }

  return parsedCollections;
}

function parseSelection(selection : any) {
  if (!isSelection(selection))
    throw new TypeError('Selection is not a selection');
  return selection;
}

export function parseSelections(selections : any) {
  const parsedSelections : { [id : number] : Selection } = {};
  for (const selectionId in selections) {
    if (typeof selectionId !== 'string')
      throw new TypeError('Selection id is not a string');

    const id = parseInt(selectionId);
    if (isNaN(id)) throw new TypeError('Selection id is not a number');

    parsedSelections[id] = parseSelection(selections[id]);
  }

  return parsedSelections;
}

export async function createAssembly(
  { assembly } : { assembly : Assembly },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(ASSEMBLIES_PATH),
    methods.post,
    { assembly },
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('assembly', response.assembly);
  }
}

export async function retrieveAssemblies(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(ASSEMBLIES_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseAssemblies(response.assemblies);
  } catch {
    throw new DeserializationError('assemblies', response.assemblies);
  }
}

export async function retrieveAssembly(
  { assemblyId } : { assemblyId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ASSEMBLIES_PATH}${assemblyId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('assembly', response.assembly);
  }
}

export async function updateAssembly(
  { assembly } : { assembly : Assembly },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ASSEMBLIES_PATH}${assembly.id}`),
    methods.put,
    { assembly },
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('assembly', response.assembly);
  }
}

export async function deleteAssembly(
  { assembly } : { assembly : Assembly },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ASSEMBLIES_PATH}${assembly.id}`),
    methods.delete,
    {},
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('assembly', response.assembly);
  }
}

export async function addAssemblyToProduct(
  { product, assembly } : { product : Product, assembly : Assembly },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ASSEMBLIES_PATH}${assembly.id}/products/${product.id}`),
    methods.post,
    {},
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('assembly', response.assembly);
  }
}

export async function removeAssemblyFromProduct(
  { product, assembly } : { product : Product, assembly : Assembly },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ASSEMBLIES_PATH}${assembly.id}/products/${product.id}`),
    methods.delete,
    {},
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('assembly', response.assembly);
  }
}

export async function createCollection(
  { collection } : { collection : Collection },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(COLLECTIONS_PATH),
    methods.post,
    { collection },
    options,
  );

  try {
    return parseCollection(response.collection);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function retrieveCollections(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(COLLECTIONS_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCollections(response.collections);
  } catch {
    throw new DeserializationError('collections', response.collections);
  }
}

export async function retrieveCollection(
  { collectionId : id } : { collectionId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${COLLECTIONS_PATH}${id}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCollection(response.collection);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function updateCollection(
  { collection } : { collection : Collection },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${COLLECTIONS_PATH}${collection.id}`),
    methods.put,
    { collection },
    options,
  );

  try {
    return parseCollection(response.collection);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function deleteCollection(
  { collection } : { collection : Collection },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${COLLECTIONS_PATH}${collection.id}`),
    methods.delete,
    {},
    options,
  );

  try {
    return parseCollection(response.collection);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function addTagToCollection(
  { collection, tag } : { collection : Collection, tag : Tag },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${COLLECTIONS_PATH}${collection.id}/tags/${tag.id}`),
    methods.post,
    {},
    options,
  );

  try {
    return parseCollection(response.collection);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function removeTagFromCollection(
  { collection, tag } : { collection : Collection, tag : Tag },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${COLLECTIONS_PATH}${collection.id}/tags/${tag.id}`),
    methods.delete,
    {},
    options,
  );

  try {
    return parseCollection(response.collection);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function addCollectionToAssembly(
  { collection, assembly } : { collection : Collection, assembly : Assembly },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ASSEMBLIES_PATH}${assembly.id}/collections/${collection.id}`),
    methods.post,
    {},
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function removeCollectionFromAssembly(
  { collection, assembly } : { collection : Collection, assembly : Assembly },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ASSEMBLIES_PATH}${assembly.id}/collections/${collection.id}`),
    methods.delete,
    {},
    options,
  );

  try {
    return parseAssembly(response.assembly);
  } catch {
    throw new DeserializationError('collection', response.collection);
  }
}

export async function createSelection(
  { selection } : { selection : Selection },
  options? : RequestOptions,
) {
  const path = options?.token
    ? SELECTIONS_PATH
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/`;

  const response = await request(
    getUrl(path),
    methods.post,
    { selection },
    options,
  );

  try {
    return {
      selection : parseSelection(response.selection),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError('selection', response.selection);
  }
}

export async function retrieveSelections(
  input : {},
  options? : RequestOptions,
) {
  const path = options?.token
    ? SELECTIONS_PATH
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/`;

  const response = await request(
    getUrl(path),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseSelections(response.selections);
  } catch {
    throw new DeserializationError('selections', response.selections);
  }
}

export async function retrieveSelection(
  { selectionId } : { selectionId : number },
  options? : RequestOptions,
) {
  const path = options?.token
    ? `${SELECTIONS_PATH}${selectionId}`
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/${selectionId}`;

  const response = await request(
    getUrl(path),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseSelection(response.selection);
  } catch {
    throw new DeserializationError('selection', response.selection);
  }
}

export async function updateSelection(
  { selection } : { selection : Selection },
  options? : RequestOptions,
) {
  const path = options?.token
    ? `${SELECTIONS_PATH}${selection.id}`
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/${selection.id}`;

  const response = await request(
    getUrl(path),
    methods.put,
    { selection },
    options,
  );

  try {
    return {
      selection : parseSelection(response.selection),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError('selection', response.selection);
  }
}

export async function deleteSelection(
  { selection } : { selection : Selection },
  options? : RequestOptions,
) {
  const path = options?.token
    ? `${SELECTIONS_PATH}${selection.id}`
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/${selection.id}`;

  const response = await request(
    getUrl(path),
    methods.delete,
    {},
    options,
  );

  try {
    return {
      selection : parseSelection(response.selection),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError('selection', response.selection);
  }
}

export async function bulkCreateSelections({
  selections,
  lineItems,
} : {
  selections : Selection[],
  lineItems? : LineItem[],
}, options? : RequestOptions,
) {
  const path = options?.token
    ? `${SELECTIONS_PATH}bulk/`
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/bulk/`;

  const response = await request(
    getUrl(path),
    methods.post,
    {
      selections,
      lineItems : lineItems?.map((lineItem) => ({
        ...lineItem,
        price : undefined,
      })),
    },
    options,
  );

  try {
    return {
      selections : parseSelections(response.selections),
      lineItems : parseLineItems(response.lineItems),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError('selections', response.selections);
  }
}

export async function bulkRetrieveSelections(
  { selectionIds } : { selectionIds : number[] },
  options? : RequestOptions,
) {
  const path = options?.token
    ? `${SELECTIONS_PATH}bulk/`
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/bulk/`;

  const response = await request(
    getUrl(
      path,
      selectionIds.map(id => ['id', id.toString()]),
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseSelections(response.selections);
  } catch {
    throw new DeserializationError('selections', response.selections);
  }
}

export async function bulkUpdateSelections({
  lineItems,
  selections,
  deleteSelections = [],
} : {
  lineItems? : LineItem[],
  selections : Selection[],
  deleteSelections? : Selection[],
}, options? : RequestOptions) {
  const path = options?.token
    ? `${SELECTIONS_PATH}bulk/`
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/bulk/`;

  const deleteIds = deleteSelections.map((selection) => selection.id)
    .filter(id => id !== undefined) as number[];

  if (deleteIds.length !== deleteSelections.length) {
    throw new APIError('Fulfilment id is required');
  }

  const response = await request(
    getUrl(path),
    methods.put,
    {
      lineItems,
      selections,
      deleteSelections : deleteIds,
    },
    options,
  );

  try {
    return {
      lineItems : parseLineItems(response.lineItems),
      selections : parseSelections(response.selections),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError('selections', response.selections);
  }
}

export async function bulkDeleteSelections(
  { selections } : { selections : Selection[] },
  options? : RequestOptions,
) {
  const path = options?.token
    ? `${SELECTIONS_PATH}bulk/`
    : `${SELECTIONS_PATH}guest/${await getGuestCode()}/bulk/`;

  const response = await request(
    getUrl(path),
    methods.delete,
    { selectionIds : selections.map((selection) => selection.id) },
    options,
  );

  try {
    return {
      selections : parseSelections(response.selections),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError('selections', response.selections);
  }
}
