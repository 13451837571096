import { useCallback, useContext } from 'react';

import {
  Address,
  Assembly,
  Collection,
  Customer,
  Currency,
  Location,
  Product,
  ServiceChannel,
  TimeSlot,
  LineItem,
  Fulfilment,
  Selection,
  DraftCustomOrder,
} from '#mrktbox/clerk/types';

import OptionsContext from '#mrktbox/clerk/context/OptionsContext';

import useProducts from '#mrktbox/clerk/hooks/useProducts';
import useTags from '#mrktbox/clerk/hooks/useTags';
import useOrders, { draftEmptyOrder } from '#mrktbox/clerk/hooks/useOrders';

import { listRecords } from '#mrktbox/clerk/utils/data';

export function draftEmptyCustomOrder() : DraftCustomOrder {
  return {
    ...draftEmptyOrder(),
    selections : {},
  };
}

function useOptions() {
  const {
    products,
    loaded : productsLoaded,
    load : loadProducts,
  } = useProducts();
  const {
    tags,
    loaded : tagsLoaded,
    load : loadTags,
    addProductToTag,
    removeProductFromTag,
  } = useTags();
  const {
    lineItems : allLineItems,
    loaded : ordersLoaded,
    load : loadOrders,
    findOrder,
    createDefaultOrder,
  } = useOrders();

  const contextHooks = useContext(OptionsContext);

  const assembies = contextHooks.assemblies;
  const collections = contextHooks.collections;
  const selections = contextHooks.selections;
  const load = contextHooks.load;
  const createAssembly = contextHooks.createAssembly;
  const createCollection = contextHooks.createCollection;
  const refreshCollection = contextHooks.refreshCollection;
  const addAssemblyToProduct = contextHooks.addAssemblyToProduct;
  const addCollectionToAssembly = contextHooks.addCollectionToAssembly;
  const customiseOrder = contextHooks.customiseOrder;
  const bulkCreateSelections = contextHooks.bulkCreateSelections;
  const bulkUpdateSelections = contextHooks.bulkUpdateSelections;

  const createProductAssembly = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const newAssembly = await createAssembly(assembly);
    if (!newAssembly) return null;
    return addAssemblyToProduct(product, newAssembly);
  }, [createAssembly, addAssemblyToProduct]);

  const createAssemblyCollection = useCallback(async (
    assembly : Assembly,
    collection : Collection,
  ) => {
    const newCollection = await createCollection(collection);
    if (!newCollection) return null;
    return addCollectionToAssembly(assembly, newCollection);
  }, [createCollection, addCollectionToAssembly]);

  const addProductToCollection = useCallback(async (
    collection : Collection,
    product : Product,
  ) => {
    const newTag = await addProductToTag(collection, product);
    if (!newTag || !collection.id) return null;
    return refreshCollection(collection.id);
  }, [addProductToTag, refreshCollection]);

  const bulkUpdateCustomisedLineItems = useCallback(async (
    lineItems : LineItem[],
    itemSelections : Selection[],
  ) => {
    const removedSelections = listRecords(selections).filter(
      selection => lineItems.some(item => item.id === selection.lineItemId)
        && !itemSelections.some(s => s.id === selection.id)
    );

    const newSelections = await bulkUpdateSelections(
      itemSelections,
      {
        lineItems,
        deleteSelections : removedSelections,
      },
    );

    return newSelections;
  }, [
    selections,
    bulkUpdateSelections,
  ]);

  const removeProductFromCollection = useCallback(async (
    collection : Collection,
    product : Product,
  ) => {
    const newTag = await removeProductFromTag(collection, product);
    if (!newTag || !collection.id) return null;
    return refreshCollection(collection.id);
  }, [removeProductFromTag, refreshCollection]);

  const getProductAssemblies = useCallback((product : Product) => {
    return assembies
      ? listRecords(assembies).filter(
        assembly => product.id && assembly.productIds.includes(product.id)
      )
      : [];
  }, [assembies]);

  const getAssemblyCollections = useCallback((assembly : Assembly) => {
    return collections
      ? listRecords(collections).filter(
        collection => collection.id &&
          assembly.collectionIds.includes(collection.id),
      )
      : [];
  }, [collections]);

  const getCollectionTags = useCallback((collection : Collection) => {
    return tags
    ? listRecords(tags).filter(
      tag => tag.id && collection.tagIds.includes(tag.id),
      )
      : [];
    }, [tags]);

  const getCollectionProducts = useCallback((collection : Collection) => {
    const tags = getCollectionTags(collection);
    return products
      ? listRecords(products).filter(
        product => product.id && (
          collection.productIds.includes(product.id)
            || tags.some(tag => product.id
              && tag.productIds.includes(product.id))
        ),
      )
      : [];
  }, [products, getCollectionTags]);

  const getCollection = useCallback((assembly : Assembly, date : Date) => {
    const collections = getAssemblyCollections(assembly).sort(
      (a, b) => {
        if (a.starting === null) return 1;
        if (b.starting === null) return -1;
        return b.starting.getTime() - a.starting.getTime();
      }
    );
    return collections.find((col : Collection) => (
      ((col.starting === null) ||
        (col.starting.getTime() <= date.getTime())) &&
      ((col.ending === null) ||
        (col.ending.getTime() >= date.getTime()))
    ));
  }, [getAssemblyCollections]);

  const getAssemblyProducts = useCallback(
    (assembly : Assembly, date : Date) => {
      const collection = getCollection(assembly, date);
      return collection ? getCollectionProducts(collection) : [];
    },
    [getCollection, getCollectionProducts],
  );

  const getAssemblyCounts = useCallback(
    (assembly : Assembly, date : Date) => {
      const collection = getCollection(assembly, date);
      return collection ? {
        min : collection.min,
        max : collection.max,
      } : {
        min : 0,
        max : 0,
      };
    },
    [getCollection],
  );

  const isProductCustomisable = useCallback((
    product : Product,
    date : Date,
  ) => {
    const assemblies = getProductAssemblies(product);
    return assemblies.some(
      assembly => getAssemblyProducts(assembly, date).length > 0
    );
  }, [getProductAssemblies, getAssemblyProducts]);

  const validateSelections = useCallback((
    product : Product,
    selections : Selection[],
    date : Date,
  ) => {
    const errors = {} as { [id : number] : {
      key : 'invalidAssembly'
        | 'invalidProduct'
        | 'tooFew'
        | 'tooMany',
      assembly : Assembly,
      product? : Product,
    } };

    const assemblies = getProductAssemblies(product);
    for (const selection of selections) {
      if (!assemblies.some(a => a.id === selection.assemblyId)) {
        const badAssembly = assemblies.find(a => a.id === selection.assemblyId);
        if (!badAssembly) continue;
        errors[selection.assemblyId] = {
          key : 'invalidAssembly',
          assembly : badAssembly,
        };
      }
    }

    for (const assembly of assemblies) {
      const assemblyId = assembly.id;
      if (!assemblyId) continue;

      const { min, max } = getAssemblyCounts(assembly, date);
      const selected = selections.filter(
        selection => selection.assemblyId === assemblyId
      );

      const products = getAssemblyProducts(assembly, date);
      if (selected.some(selection => {
        if (!products.some(p => p.id === selection.productId)) {
          errors[assemblyId] = {
            key : 'invalidProduct',
            assembly,
            product : products.find(p => p.id === selection.productId),
          };
          return true;
        }
        return false;
      })) continue;

      const count = selected.reduce((sum, s) => sum + s.quantity, 0);
      if (max !== null && count > max) {
        errors[assemblyId] = {
          key : 'tooMany',
          assembly,
        };
        continue;
      }

      if (count < min) errors[assemblyId] = {
        key : 'tooFew',
        assembly,
      };
    }

    return {
      valid : Object.keys(errors).length === 0,
      errors : errors,
    };
  }, [getProductAssemblies, getAssemblyCounts, getAssemblyProducts]);

  const calculateSelectionPrice = useCallback(
    (selection : Selection) : Currency | null => {
      const assembly = assembies && assembies[selection.assemblyId];
      const product = products && products[selection.productId];
      if (!assembly || !product) return null;

      if (assembly.complimentary) return {
        amount : 0,
        currencyCode : product.price.currencyCode,
        increment : product?.price?.increment,
        calculatedValue : 0,
      };

      return {
        ...product.price,
        amount : product.price.amount * selection.quantity,
        calculatedValue : product.price.amount
          * product.price.increment
          * selection.quantity,
      };
    },
    [assembies, products],
  );

  const generateDefaultOrder = useCallback(() => {
    return {
      ...createDefaultOrder(),
      selections : [],
    } as DraftCustomOrder;
  }, [createDefaultOrder]);

  const findCustomOrder = useCallback(async ({
    address,
    customer,
    serviceChannel,
    location,
    timeSlot,
    iteration,
    division,
  } : {
    address : Address | null,
    customer : Customer | null,
    serviceChannel : ServiceChannel | null,
    location : Location | null,
    timeSlot : TimeSlot | null,
    iteration : number,
    division : number,
  }) => {
    const order = await findOrder({
      address,
      customer,
      serviceChannel,
      location,
      timeSlot,
      iteration,
      division,
    });
    if (!order) return null;
    return customiseOrder(order);
  }, [findOrder, customiseOrder]);

  const listOrphanedFulfilments = useCallback((
    order : DraftCustomOrder,
  ) : {
    fulfilments : Fulfilment[],
    lineItems : LineItem[],
  } => {
    const orderRecord = order.order;
    if (!orderRecord) return ({ fulfilments : [], lineItems : [] });

    const fulfilments = Object.values(orderRecord.fulfilments).filter(
      (fulfilment) => (
        !Object.values(order.lineItems).some(
          (lineItem) => lineItem.id === fulfilment.lineItemId
            && lineItem.productId === fulfilment.requestedProductId
        )
          && !Object.values(order.selections).some(
            (selection) => selection.lineItemId === fulfilment.lineItemId
              && selection.productId === fulfilment.requestedProductId
          )
      )
    );

    const missingItems = fulfilments.map((fulfilment) => {
      const lineItem = allLineItems?.[fulfilment.lineItemId];
      return {
        ...lineItem ?? orderRecord,
        id : lineItem?.id,
        productId : fulfilment.fulfilledProductId
          ?? fulfilment.requestedProductId,
        quantity : fulfilment.fulfilledQty ?? fulfilment.requestedQty,
        price : fulfilment.price,
      };
    });

    return {
      fulfilments,
      lineItems : missingItems,
    };
  }, [allLineItems]);

  const canEditItem = useCallback((
    order : DraftCustomOrder,
    lineItem : LineItem,
  ) => {
    return (
      !order.order
        || !Object.values(order.order.fulfilments).some(
          (fulfilment) => fulfilment.lineItemId === lineItem.id
        )
    );
  }, []);

  const loadOptions = useCallback(() => {
    load();
    loadProducts();
    loadTags();
    loadOrders();
  }, [load, loadProducts, loadTags, loadOrders]);

  return {
    ...contextHooks,
    loaded : contextHooks.assembliesLoaded
      && contextHooks.collectionsLoaded
      && contextHooks.selectionsLoaded
      && productsLoaded
      && tagsLoaded
      && ordersLoaded,
    load : loadOptions,
    createProductAssembly,
    createAssemblyCollection,
    addProductToCollection,
    removeProductFromCollection,
    getProductAssemblies,
    getAssemblyCollections,
    getCollectionProducts,
    getCollectionTags,
    getAssemblyProducts,
    getAssemblyCounts,
    findCustomOrder,
    isProductCustomisable,
    validateSelections,
    calculateSelectionPrice,
    bulkCreateCustomisedLineItems : bulkCreateSelections,
    bulkUpdateCustomisedLineItems,
    createDefaultOrder : generateDefaultOrder,
    listOrphanedFulfilments,
    canEditItem,
    draftEmptyOrder : draftEmptyCustomOrder,
  }
}

export default useOptions;
