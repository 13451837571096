import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface CheckSummaryViewStyle extends Style {
  updating? : boolean;
}

const SummaryView = styled.div<CheckSummaryViewStyle>`
  position: relative;
  z-index: 2;
  width: calc(100% - ${(props) => props.theme.layout.padding} * 2);
  padding: 0;
  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props.theme.border.colour};
  border-top-width: ${(props) => props.theme.border.width};
  margin: 2rem ${(props) => props.theme.layout.padding} 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - ${(props) => props.theme.layout.padding} * 2);
    margin: 1rem ${(props) => props.theme.layout.padding} 1rem;
  }

  & > li {
    opacity: ${(props) => (props.updating ? '0.5' : '1')};
  }
`;

const SummaryTotalView = styled.div<Style>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 24rem;
  padding: 2rem 0 0;
  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props.theme.border.colour};
  border-top-width: ${(props) => props.theme.border.width};
  margin: 1rem 0 0 calc(100% - 24rem);
  text-align: right;
  font-weight: ${(props) => props.theme.boldWeight};
  font-size: ${(props) => props.theme.fonts.sizes.big};
  color: ${(props) => props.theme.colours.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin: 1rem 0 0;
  }

  span {
    display: block;
  }
`;

interface TotalsProps {
  total? : Currency;
  children? : React.ReactNode;
}

function Totals({
  total,
  children,
} : TotalsProps) {
  return (
    <SummaryView>
      { children }
      <SummaryTotalView>
        <span>Total</span>
        { total && <span>{ formatCurrency(total) }</span> }
      </SummaryTotalView>
    </SummaryView>
  );
}

export default Totals;
