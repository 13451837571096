
export const paths = {
  external : {
    home : process.env.REACT_APP_HOME_HREF,
  },
  orders : {
    itemReport : '/orders/items',
  },
}

export function getLocation() { return window.location; }

export function generatePath(path : string, params : any) {
  return `${path}?${new URLSearchParams(params).toString()}`
}

export function openTab(url : string) {
  window.open(`${window.location.origin}${url}`);
}

export function generateUrl(path : string, params : any) {
  const origin = getLocation().origin;
  return `${origin}${generatePath(path, params)}`;
}

export const isRouteActive = (route : string) => {
  const path = getLocation().pathname.replace(/\/+$/, '');
  return path.startsWith(route.replace(/\/+$/, ''));
}
