import { useCallback, useContext } from 'react';

import { Customer, CreditCard } from '#mrktbox/clerk/types';

import CardsContext from '#mrktbox/clerk/context/CardsContext';

import useNavigation from '#mrktbox/clerk/hooks/useNavigation';

function useCards() {
  const {
    creditCards,
    loaded,
    load,
    createCreditCard,
    refreshCreditCards,
    refreshCreditCard,
    retrieveCreditCards,
    retrieveCreditCard,
    deleteCreditCard,
  } = useContext(CardsContext);

  const { generateUrl } = useNavigation();

  const createCreditCardWithValidation = useCallback(
    async (
      creditCard : CreditCard,
      customer : Customer,
      validation : {
        token : string,
        postal : string,
      } | string,
      postal? : string,
    ) => {
      const token = typeof validation === 'string'
        ? validation
        : validation.token;
      const postalCode = typeof validation === 'string'
        ? postal
        : validation.postal;
      if (!token || !postalCode) return;
      return await createCreditCard(
        creditCard,
        customer,
        { token, postal : postalCode },
      );
    },
    [createCreditCard],
  );

  const generateRegistrationLink = useCallback((customer : Customer) =>{
    const { id, defaultName : name, defaultEmail : email } = customer;
    return generateUrl(
      '/cards/register/',
      { customerId : id, email, name }
    );
  }, [generateUrl]);

  return {
    creditCards,
    loaded,
    load,
    refreshCreditCards,
    refreshCreditCard,
    retrieveCreditCards,
    retrieveCreditCard,
    createCreditCard : createCreditCardWithValidation,
    deleteCreditCard,
    generateRegistrationLink,
  };
}

export default useCards;
