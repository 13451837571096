
export function isNumberKey(key : any) {
  return !isNaN(parseInt(key));
}

export function isNumberKeyedObject(numberKeyedObject : any) {
  return (
    typeof numberKeyedObject === 'object' &&
    Object.keys(numberKeyedObject).every(isNumberKey)
  );
}
