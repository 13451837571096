import React from 'react';

import { ServiceChannel } from '@mrktbox/clerk/types';

import NavigationButton from '#materials/NavigationButton';

import ServiceIcon from '#components/orders/ServiceIcon';

interface OrderTypeLinkProps {
  channel : ServiceChannel;
  href? : string;
  description? : string;
  icon? : React.ReactNode;
  disabled? : boolean;
  index? : number;
  onClick? : () => void;
}

function OrderTypeLink({
  channel,
  href,
  description,
  icon,
  disabled = false,
  index = 0,
  onClick,
} : OrderTypeLinkProps) {

  return (
    <NavigationButton
      title={channel.name}
      subtitle={channel.active
        ? (description ?? channel.description)
        : 'Currently unavailable'}
      disabled={disabled || !channel.active}
      href={href}
      onClick={onClick}
      icon={icon ?? <ServiceIcon channel={channel} />}
      index={index}
    />
  );
}

export default OrderTypeLink;
