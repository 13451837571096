import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { DraftOrder } from '#mrktbox/types';
import { useNavigation, useSubscriptions } from '#mrktbox';

import { Theme } from '#types';

import useModal from '#hooks/useModal';
import useRequests from '#hooks/useRequests';

import Heading from '#materials/Heading';
import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import DateTimeSelect from '#materials/DateTimeSelect';
import DateTimeOptions from '#materials/DateTimeOptions';

import ModalView from '#components/modal/ModalView';
import ModalClose from '#components/modal/ModalClose';
import SubscriptionChange from '#components/orders/SubscriptionChange';

import { formatDateTime, formats } from '#mrktbox/utils';

interface OrderTimeModalViewStyle {
  width : string;
}

function formatButton(time : Date | null) {
  return time
    ? `Order ${formatDateTime(time, formats.easy)}`
    : 'Select a Time';
}

interface Style { theme? : Theme; }

const OrderTimeModalView = styled(ModalView)<OrderTimeModalViewStyle>`
  width: ${(props) => props.width};

  & > div {
    padding: 3.5rem 3.5rem 4rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 3rem ${(props) => props.theme.layout.paddingMobile};
    }
  }
`;

const DateTimeView = styled.div`
  text-align: center;
`;

const DateTimeTitle = styled.p<Style>`
  width: 100%;
  margin: 0 0 1rem;
  text-align: center;
  line-height: ${(props) => props.theme.lineHeight};
  font-size: ${(props) => props.theme.fonts.sizes.h3};
`;

const DateTimeAsap = styled.div<Style>`
  width: fit-content;
  margin: 2rem auto 3rem;
  text-align: center;

  button {
    min-width: 24rem;
    max-width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      min-width: 100%;
    }
  }
`;

const DateTimeNevermind = styled.div<Style>`
  button {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`;

function OrderTime() {
  const { navigate } = useNavigation();
  const { openModal, closeModal } = useModal();
  const { isOrderRecurring } = useSubscriptions();
  const {
    currentOrder,
    time : orderTime,
    availableTimes,
    fixedWindows,
    setTime : setOrderTime,
    findOrder,
    isOrderOpen,
    generateOrderUrl,
  } = useRequests();

  const [time, setTime] = useState<Date | null>(orderTime);
  const [times, setTimes] = useState<Date[]>([]);
  const [processing, setProcessing] = useState(false);
  const [existingOrder, setExistingOrder] = useState<DraftOrder | null>(null);
  const [merge, setMerge] = useState(false);
  const [closed, setClosed] = useState(false);

  const handleSubscriptionChange = useCallback((
    time : Date | null,
  ) => async (target : 'this' | 'future') => {
    setProcessing(true);
    const success = await setOrderTime(time, { moveItems : true, target });
    setProcessing(false);
    if (success) closeModal();
  }, [setOrderTime, closeModal]);

  const handleSubmit = useCallback(async (value? : Date | null) => {
    if (closed) {
      if (existingOrder) navigate(generateOrderUrl(existingOrder, true));
      return;
    }

    if (!time || !currentOrder) return;

    if (isOrderRecurring(currentOrder)) {
      openModal(
        <SubscriptionChange
          title="Update all subscriptions, or just this order?"
          prompt={`This order contains subscirption items.\n Would you like to`
            + ' apply changes to this order only'
            + (currentOrder.time
              ? ` (${formatDateTime(currentOrder.time, formats.easy)})`
              : '')
            + ', or all future orders?'}
          selectTarget={handleSubscriptionChange(
            value !== undefined ? value : time,
          )}
        />
      );
      return;
    }

    setProcessing(true);
    const success = await setOrderTime(
      value !== undefined ? value : time,
      { moveItems : true },
    );
    setProcessing(false);
    if (success) closeModal();
  }, [
    existingOrder,
    handleSubscriptionChange,
    currentOrder,
    time,
    closed,
    setOrderTime,
    isOrderRecurring,
    openModal,
    closeModal,
    generateOrderUrl,
    navigate,
  ]);

  useEffect(() => {
    if (availableTimes && availableTimes.length) {
      const times = availableTimes.map((time) => time.start)
        .sort((a, b) => a.getTime() - b.getTime());
      setTimes(times);

      if (
        !orderTime
          || !times.some(t => t.getTime() === orderTime.getTime())
      ) setTime(times[0]);
      return;
    }

    setTimes([]);
    if (!orderTime) setTime(orderTime);
  }, [orderTime, availableTimes]);

  useEffect(() => {
    const existing = findOrder({ time });
    setExistingOrder(existing);
    setMerge((time?.getTime() !== orderTime?.getTime()) && !!existing);
    setClosed(!!existing && !isOrderOpen(existing));
  }, [time, orderTime, findOrder, isOrderOpen]);

  return (
    <OrderTimeModalView width={!fixedWindows ? '40rem' : '56rem'}>
      <ModalClose onClick={closeModal} />
      <DateTimeView>
        <DateTimeTitle>
          <Heading>
            Schedule Your Order
          </Heading>
        </DateTimeTitle>
        <p>Please choose a date & time below.</p>
        { !fixedWindows
          ? (<>
            <DateTimeSelect
              value={time}
              options={times}
              onChange={setTime}
            />
            { merge && (
              <p>
                { "You've already scheduled an order for this time."
                  + (closed
                    ? " Please select another time."
                    : " We'll merge this order with the other.")
                }
              </p>
            ) }
            <DateTimeAsap>
              <ButtonStyled
                onClick={handleSubmit}
                colour="primary"
                disabled={!times.length && !closed}
              >
                { merge
                  ? (closed ? 'View Order' : 'Merge')
                  : formatButton(time)
                }
              </ButtonStyled>
            </DateTimeAsap>
          </>)
          : (<DateTimeOptions
            options={availableTimes}
            selected={orderTime}
            onSelect={handleSubmit}
            disabled={processing}
          />)
        }
        <DateTimeNevermind>
          <ButtonLink onClick={closeModal}>
            { orderTime
              ? "Nevermind, keep current time"
              : "Nevermind, I'll choose later"
            }
          </ButtonLink>
        </DateTimeNevermind>
      </DateTimeView>
    </OrderTimeModalView>
  );
}

export default OrderTime;
