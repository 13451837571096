import { useContext } from 'react';

import RevenueCentreContext from '#mrktbox/clerk/context/RevenueCentreContext';

function usePayments(){
  const {
    revenueCentres,
    loaded,
    load,
    refreshRevenueCentres,
    refreshRevenueCentre,
    retrieveRevenueCentres,
    retrieveRevenueCentre,
    createRevenueCentre,
    updateRevenueCentre,
    deleteRevenueCentre,
    addServiceToRevenueCentre,
    removeServiceFromRevenueCentre,
  } = useContext(RevenueCentreContext);

  return {
    revenueCentres,
    loaded,
    load,
    refreshRevenueCentres,
    refreshRevenueCentre,
    retrieveRevenueCentres,
    retrieveRevenueCentre,
    createRevenueCentre,
    updateRevenueCentre,
    deleteRevenueCentre,
    addServiceToRevenueCentre,
    removeServiceFromRevenueCentre,
  };
}

export default usePayments;
