import { Currency, isCurrency } from './payments';

export type AdjustmentScope = 'order' | 'lineItem';
export type ConditionScope = 'order' | 'account';

export interface Adjustment {
  id? : number;
  name : string;
  scope : AdjustmentScope;
  factor : number;
  currency : Currency;
  conditionIds : number[],
  taxIds : number[],
}

export interface Condition {
  id? : number;
  scope : ConditionScope;
  count : number;
  serviceIds : number[];
  productIds : number[];
}

export function isAdjustment(adjustment : any) : adjustment is Adjustment {
  return (
    typeof adjustment === 'object' &&
    (adjustment.id === undefined || typeof adjustment.id === 'number') &&
    typeof adjustment.name === 'string' &&
    typeof adjustment.scope === 'string' &&
    typeof adjustment.factor === 'number' &&
    isCurrency(adjustment.currency) &&
    Array.isArray(adjustment.conditionIds) &&
    adjustment.conditionIds.every((id : any) => typeof id === 'number')
  )
}

export function isCondition(condition : any) : condition is Condition {
  return (
    typeof condition === 'object' &&
    (condition.id === undefined || typeof condition.id === 'number') &&
    typeof condition.scope === 'string' &&
    typeof condition.count === 'number' &&
    Array.isArray(condition.serviceIds) &&
    condition.serviceIds.every((id : any) => typeof id === 'number') &&
    Array.isArray(condition.productIds) &&
    condition.productIds.every((id : any) => typeof id === 'number')
  )
}
