import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { useNavigation, useOptions, useSubscriptions } from '#mrktbox';
import { LineItem, ProjectedOrder } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

import useSidebar from '#hooks/useSidebar';
import useRequests from '#hooks/useRequests';
import useCatalogue from '#hooks/useCatalogue';

import ButtonLink from '#materials/ButtonLink';
import Heading from '#materials/Heading';
import Body from '#materials/Body';
import { Refresh } from '#materials/icons';

import ProductImage from '#components/products/ProductImage';
import Quantity from '#components/cart/Quantity';

interface Style { theme? : Theme; }

const CartItemView = styled.span<Style>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;
  border-bottom-style: solid;
  border-bottom-width: ${(props) => props.theme.border.width};
  border-bottom-color: ${(props) => props.theme.border.colour};

  &:last-of-type {
    border: 0;
  }
`;

const CartItemImage = styled.div<Style>`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.bgColours.tertiary};
`;

const CartItemInfo = styled.span`
  display: block;
  flex-grow: 1;
  padding: 0 1.75rem;
`;

const CartItemName = styled(Heading)`
  display: block;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`;

const CartItemDescription = styled(Body)`
  display: block;
  margin-top: 0.3rem;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
`

const CartItemDetails = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap : 0.5rem;
  align-items: center;
`;

const CartItemPrice = styled(Heading)`
  display: block;
  white-space: nowrap;
  margin : 0.6rem 1rem 0 0;
  margin-right: 1rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`;

interface CartItemLinkStyle extends Style { direction? : 'row' | 'column'; }

const CartItemLink = styled.span<CartItemLinkStyle>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  ${(props) => (props.direction === 'column') && 'align-items: flex-start;'}
  flex-wrap: wrap;
  gap: ${(props) => (props.direction === 'column') ? '0' : '0.5rem'};
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};

  a, button {
    margin: 0.75rem 1rem 0 0;
    text-align: left;
  }
`;

const SubscriptionLink = styled(CartItemLink)`
  * {
    margin-right: 0.5rem;
  }
`

const CartItemQuantity = styled.div`
  width: 9.2rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

interface CartItemProps {
  item : LineItem;
  order? : ProjectedOrder | null;
  setBusy? : (busy : boolean) => void;
}

function CartItem({
  item,
  order,
  setBusy,
} : CartItemProps) {
  const { navigate } = useNavigation();
  const { closeSidebar } = useSidebar();
  const { isProductCustomisable, calculateLinePrice } = useOptions();
  const { findLineItemSubscription } = useSubscriptions();
  const {
    serviceChannel,
    time,
    removeItem,
    updateItem,
    canUpdateItem,
    canSubscribe,
    evaluateReccurence,
    formatRecurrence,
  } = useRequests();
  const {
    allProducts : products,
    getProductSlug,
    isProductAvailable,
    isProductStocked,
  } = useCatalogue();

  const [subscribing, setSubscribing] = useState(false);
  const [newQty, setNewQty] = useState<number | null>(null);

  const product = products?.[item.productId] ?? null;
  const selections = order?.selections
    ? Object.values(order.selections).filter((s) => s.lineItemId === item.id)
    : [];
  const subscription = order ? findLineItemSubscription(item, order) : null;
  const recurring = order ? evaluateReccurence(
    subscription,
    { iteration : order.timeSlotIteration },
  ) : 0;
  const [deleting, setDeleting] = useState(false);

  const quantity = subscription?.quantity ?? item.quantity;

  const handleRemove = useCallback(() => {
    setNewQty(null);
    if (recurring) setDeleting(true);
    else if (subscription) removeItem(item, { target : 'future' });
    else removeItem(item);
  }, [item, subscription, recurring, removeItem]);

  const handleRemoveSubscription = useCallback((
    target : 'this' | 'future',
  ) => async () => {
    const success = await removeItem(item, { target });
    if (success) setDeleting(false);
  }, [item, removeItem]);

  const handleSetQuantity = useCallback((qty : number) => {
    if (qty < 1) handleRemove();
    else if (recurring) setNewQty(qty);
    else if (quantity === qty) return;
    else if (subscription) {
      updateItem(item, { quantity: qty }, undefined, { target : 'this' });
    }
    else updateItem(item, { quantity: qty });
  }, [item, quantity, recurring, subscription, handleRemove, updateItem]);

  const handleIncrement = useCallback((count : number) => () => {
    handleSetQuantity((newQty ?? quantity) + count);
  }, [quantity, newQty, handleSetQuantity]);

  const handleUpdateSubscription = useCallback((
    target : 'this' | 'future',
  ) => async () => {
    if (newQty === null) return;
    const success = await updateItem(
      item,
      { quantity : newQty },
      undefined,
      { target },
    );
    if (success) setNewQty(null);
  }, [item, newQty, updateItem]);

  const handleEdit = useCallback(() => {
    closeSidebar();
    const slug = product ? getProductSlug(product) : item.productId;
    navigate(`/product/${slug}/?lineItemId=${item.id}`);
  }, [item, product, getProductSlug, closeSidebar, navigate]);

  const handleSubscribe = useCallback((period : number) => async () => {
    const success = await updateItem(
      item,
      undefined,
      undefined,
      { period, target : 'future' },
    );
    if (success) setSubscribing(false);
  }, [item, updateItem]);

  useEffect(() => {
    if (setBusy) setBusy(subscribing || deleting || !!newQty);
  }, [subscribing, deleting, newQty, setBusy]);

  const listPrice = calculateLinePrice(item, order, { adjustments : [] });
  const price = calculateLinePrice(item, order);

  const available = product && isProductAvailable(product);
  const inStock = product && isProductStocked(product);

  const selectionsDescription = selections.map((s) => {
    if (!products) return null;
    const f = order?.order
      ? Object.values(order.order.fulfilments).find((f) => (
        f.lineItemId === s.lineItemId
          && f.requestedProductId === s.productId
      )) : undefined;
    const productId = f
      ? (f.fulfilledProductId ?? f.requestedProductId)
      : s.productId;
    return (products[productId]?.name ?? null) + ((s.quantity > 1)
      ? ` (${s.quantity})` : '');
  }).filter((s) => s !== null).join(', ');
  const description = !available
    ? 'Product Not Available for ' + (serviceChannel?.name || 'Order Type')
    : (!inStock
      ? 'Out of Stock'
      : selectionsDescription);

  const subscribable = canSubscribe();
  const subscriptionText = formatRecurrence(recurring);

  const canUpdate = canUpdateItem(item);

  const specialPrice = price?.amount !== listPrice?.amount;
  const showDescription = !subscribing && !deleting && !newQty;

  return (
    <CartItemView id={`line-item-${item.id}`}>
      <CartItemImage>
        <ProductImage
          product={product}
          variant="thumbnail"
          fade={!available || !inStock}
        />
      </CartItemImage>
      <CartItemInfo>
        <CartItemName>{ product?.name }</CartItemName>
        { showDescription && (
          <>
            { description && (
              <CartItemDescription>{ description }</CartItemDescription>
            ) }
            <CartItemDetails>
              <CartItemPrice>
                { (!specialPrice && price) ? formatCurrency(price) : '' }
                { (specialPrice && price && listPrice) && (
              <>
                <s>{ formatCurrency(listPrice) }</s>
                <span>{ ' ' + formatCurrency(price) }</span>
              </>
            ) }
          </CartItemPrice>
              <CartItemLink>
                { (product
                  && isProductCustomisable(product, time ?? new Date())
                  && canUpdate
                ) && (
                  <ButtonLink onClick={handleEdit}>edit</ButtonLink>
                ) }
                <ButtonLink onClick={handleRemove}>remove</ButtonLink>
              </CartItemLink>
            </CartItemDetails>
          </>
        ) }
        { subscribing && (
          <>
            <CartItemDescription>
              How often would you like your order?
            </CartItemDescription>
            <CartItemLink direction='column'>
              { !!recurring && (
                <ButtonLink onClick={handleSubscribe(0)}>
                  one time
                </ButtonLink>
              ) }
              { [1, 2, 3, 4].map((period) => (
                  <ButtonLink
                    key={period}
                    disabled={recurring === period}
                    onClick={handleSubscribe(period)}
                  >
                    { formatRecurrence(period) }
                  </ButtonLink>
                ))
              }
              <ButtonLink onClick={() => setSubscribing(false)}>
                cancel
              </ButtonLink>
            </CartItemLink>
          </>
        ) }
        { deleting && (
          <CartItemDetails>
            <CartItemDescription>
              Delete subscription, or just from this order?
            </CartItemDescription>
              <CartItemLink>
                <ButtonLink  onClick={handleRemoveSubscription('this')}>
                  this order
                </ButtonLink>
                <ButtonLink onClick={handleRemoveSubscription('future')}>
                  this & following orders
                </ButtonLink>
                <ButtonLink onClick={() => setDeleting(false)}>cancel</ButtonLink>
              </CartItemLink>
          </CartItemDetails>
        ) }
        { newQty !== null && (
          <CartItemDetails>
            <CartItemDescription>
              Update subscription, or just from this order?
            </CartItemDescription>
            <CartItemLink>
              <ButtonLink onClick={handleUpdateSubscription('this')}>
                this order
              </ButtonLink>
              <ButtonLink onClick={handleUpdateSubscription('future')}>
                this & following orders
              </ButtonLink>
              <ButtonLink onClick={() => setNewQty(null)}>cancel</ButtonLink>
            </CartItemLink>
          </CartItemDetails>
        ) }
        <SubscriptionLink>
          { (showDescription && subscribable) && (
            <ButtonLink onClick={() => setSubscribing(true)}>
              { recurring
                ? (<Refresh size={8} />)
                : null
              }
              { recurring ? subscriptionText : 'subscribe' }
            </ButtonLink>
          ) }
        </SubscriptionLink>
      </CartItemInfo>
      <CartItemQuantity>
        <Quantity
          id={`quantity-${item.productId}`}
          label={`${product?.name ?? 'Product'} Quantity`}
          count={newQty ?? quantity}
          setCount={handleSetQuantity}
          increment={handleIncrement(1)}
          decrement={handleIncrement(-1)}
          incrementDisabled={subscribing || deleting || !canUpdate}
          decrementDisabled={subscribing || deleting || !canUpdate}
        />
      </CartItemQuantity>
    </CartItemView>
  );
}

export default CartItem;
