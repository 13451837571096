
type formatMap = {
  [key : string | number] : string;
}

export function format(s : string, data : formatMap) {
  let formatted = s;
  for (const [key, value] of Object.entries(data)) {
    formatted = formatted.replace(new RegExp(`\\{${key}\\}`, 'gi'), value);
  }
  return formatted;
}
