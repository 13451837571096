import { useCallback } from 'react';

import {
  Address,
  Customer,
  CreditCard,
  ServiceChannel,
  Location,
  TimeSlot,
  Product,
  LineItem,
  Fulfilment,
  Order,
  Hold,
} from '#mrktbox/clerk/types';

import {
  claimGuestCode as claimGuestCodeAPI,
  createLineItem as createLineItemAPI,
  retrieveLineItems as retrieveLineItemsAPI,
  retrieveLineItem as retrieveLineItemAPI,
  updateLineItem as updateLineItemAPI,
  deleteLineItem as deleteLineItemAPI,
  bulkCreateLineItems as bulkCreateLineItemsAPI,
  bulkUpdateLineItems as bulkUpdateLineItemsAPI,
  bulkDeleteLineItems as bulkDeleteLineItemsAPI,
  deleteFulfilment as deleteFulfilmentAPI,
  bulkCreateFulfilments as bulkCreateFulfilmentsAPI,
  bulkUpdateFulfilments as bulkUpdateFulfilmentsAPI,
  bulkDeleteFulfilments as bulkDeleteFulfilmentsAPI,
  retrieveOrders as retrieveOrdersAPI,
  retrieveOrder as retrieveOrderAPI,
  createHold as createHoldAPI,
  retrieveHolds as retrieveHoldsAPI,
  retrieveHold as retrieveHoldAPI,
  deleteHold as deleteHoldAPI,
  payOrders as payOrdersAPI,
} from '#mrktbox/clerk/api/mrktbox/orders';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useOrdersAPI() {
  const processRequest = useProcessRequest();

  const claimGuestCode = useCallback(
    async (
      customer : Customer,
      args? : { token? : string }
    ) => {
      return await processRequest(
        claimGuestCodeAPI,
        { customer, ...args },
      )
    },
    [processRequest],
  );

  const createLineItem = useCallback(
    async (
      lineItem : LineItem,
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
        product? : Product,
      },
    ) => {
      return await processRequest(
        createLineItemAPI,
        { lineItem, ...options },
      )
    },
    [processRequest],
  );

  const retrieveLineItems = useCallback(async () => {
    return await processRequest(
      retrieveLineItemsAPI,
      {},
    )
  }, [processRequest])

  const retrieveLineItem = useCallback(
    async (lineItemId : number) => {
      return await processRequest(
        retrieveLineItemAPI,
        { lineItemId },
      )
    },
    [processRequest],
  );

  const updateLineItem = useCallback(
    async (
      lineItem : LineItem,
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      return await processRequest(
        updateLineItemAPI,
        { lineItem, ...options },
      )
    },
    [processRequest],
  );

  const deleteLineItem = useCallback(async (lineItem : LineItem) => {
    return await processRequest(
      deleteLineItemAPI,
      { lineItem },
    )
  }, [processRequest]);

  const bulkCreateLineItems = useCallback(
    async (
      lineItems : LineItem[],
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      return await processRequest(
        bulkCreateLineItemsAPI,
        { lineItems, ...options },
      )
    },
    [processRequest],
  );

  const bulkUpdateLineItems = useCallback(
    async (
      lineItems : LineItem[],
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      return await processRequest(
        bulkUpdateLineItemsAPI,
        { lineItems, ...options },
      )
    },
    [processRequest],
  );

  const bulkDeleteLineItems = useCallback(
    async (lineItems : LineItem[]) => {
      return await processRequest(
        bulkDeleteLineItemsAPI,
        { lineItems },
      )
    },
    [processRequest],
  );

  const deleteFulfilment = useCallback(
    async (fulfilment : Fulfilment) => {
      return await processRequest(
        deleteFulfilmentAPI,
        { fulfilment },
      )
    },
    [processRequest],
  );

  const bulkCreateFulfilments = useCallback(
    async (lineItems : LineItem[]) => {
      return await processRequest(
        bulkCreateFulfilmentsAPI,
        { lineItems },
      )
    },
    [processRequest],
  );

  const bulkUpdateFulfilments = useCallback(
    async (fulfilments : Fulfilment[]) => {
      return await processRequest(
        bulkUpdateFulfilmentsAPI,
        { fulfilments },
      )
    },
    [processRequest],
  );

  const bulkDeleteFulfilments = useCallback(
    async (fulfilments : Fulfilment[]) => {
      return await processRequest(
        bulkDeleteFulfilmentsAPI,
        { fulfilments },
      )
    },
    [processRequest],
  );

  const retrieveOrders = useCallback(async () => {
    return await processRequest(
      retrieveOrdersAPI,
      {},
    )
  }, [processRequest])

  const retrieveOrder = useCallback(
    async (orderId : number) => {
      return await processRequest(
        retrieveOrderAPI,
        { orderId },
      )
    },
    [processRequest],
  );

  const createHold = useCallback(
    async (hold : Hold) => {
      return await processRequest(
        createHoldAPI,
        { hold },
      )
    },
    [processRequest],
  );

  const retrieveHolds = useCallback(async () => {
    return await processRequest(
      retrieveHoldsAPI,
      {},
    )
  }, [processRequest])

  const retrieveHold = useCallback(
    async (holdId : number) => {
      return await processRequest(
        retrieveHoldAPI,
        { holdId },
      )
    },
    [processRequest],
  );

  const deleteHold = useCallback(
    async (hold : Hold) => {
      return await processRequest(
        deleteHoldAPI,
        { hold },
      )
    },
    [processRequest],
  );

  const payOrders = useCallback(
    async (
      orders : Order[],
      options? : { card? : CreditCard, token? : string },
    ) => {
      return await processRequest(
        payOrdersAPI,
        { orders, card : options?.card, token : options?.token },
      )
    },
    [processRequest],
  );

  return {
    claimGuestCode,
    createLineItem,
    retrieveLineItems,
    retrieveLineItem,
    updateLineItem,
    deleteLineItem,
    bulkCreateLineItems,
    bulkUpdateLineItems,
    bulkDeleteLineItems,
    deleteFulfilment,
    bulkCreateFulfilments,
    bulkUpdateFulfilments,
    bulkDeleteFulfilments,
    retrieveOrders,
    retrieveOrder,
    createHold,
    retrieveHolds,
    retrieveHold,
    deleteHold,
    payOrders,
  };
}

export default useOrdersAPI;
